import { Box, Container, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingComponent from "../../utils/LoadingComponent";
import i18next, { t } from "i18next";
import _ from "lodash";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const SingleNews = () => {
  const { newsId } = useParams();
  const [news, setNews] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/news/show/${newsId}`)
      .then((res) => {
        setNews(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={{ background: "#fff" }}>
      <Container
        maxWidth="lg"
        sx={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        {loading ? (
          <Box sx={{ margin: "100px 0" }}>
            <LoadingComponent />
          </Box>
        ) : _.isEmpty(news) ? (
          <Typography
            variant="h5"
            color={"GrayText"}
            sx={{ textAlign: "center", margin: "130px auto" }}
          >
            {t("no_such_news")}
          </Typography>
        ) : (
          <Box>
            <img
              src={news?.image}
              style={{ objectFit: "contain", maxHeight: "300px" }}
            />
            <Stack direction={'row'} sx={{margin: "30px 0", alignItems: 'center', gap: '10px'}}>
              <Link to={'/news'} style={{display: 'grid', placeItems: 'center'}}>{i18next.language == 'ar' ? <ArrowForward sx={{color: '#666'}}/> : <ArrowBack sx={{color: '#666'}}/>}</Link>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold" }}
              >
                {news?.title}
              </Typography>
            </Stack>
            <Typography>
              {news?.description}
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default SingleNews;
