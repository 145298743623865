import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import MemoBox from "./components/MemoBox";
import FirstSlide from "./components/FirstSlide";
import { useEffect } from "react";

const Home = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <Box
      sx={{
        "& .splide__pagination":
          i18next.language == "ar" ? { right: "0.5em", left: "auto" } : {},
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ padding: "165px 0" }}>
          <FirstSlide/>
          {/* <Splide
            aria-label="My Favorite Images"
            options={{ direction: "ttb", height: "25rem", arrows: false, pagination: false }}
          >
            <SplideSlide
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FirstSlide />
            </SplideSlide>
            <SplideSlide
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <FirstSlide />
            </SplideSlide>
          </Splide> */}
        </Box>
      </Container>
      {/* <Divider sx={{ borderColor: "rgb(255,255,255,60%)" }} />
      <Container maxWidth="lg">
        <Stack
          direction={"row"}
          sx={{
            width: "fit-content",
            margin: "auto",
            gap: "20px",
            padding: "50px 0",
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >
          <MemoBox />
          <MemoBox />
          <MemoBox />
          <MemoBox />
        </Stack>
      </Container> */}
    </Box>
  );
};

export default Home;
