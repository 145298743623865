import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import PageHeader from "./components/PageHeader";
import NavigationBox from "../utils/NavigationBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExamDetailsBox from "./components/ExamDetailsBox";
import MCQ from "./components/question-types/MCQ";
import Writing from "./components/question-types/Writing";
import VoiceRecording from "./components/question-types/VoiceRecording";
import { t } from "i18next";
import axios from "axios";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../utils/LoadingComponent";
import { FormProvider, useForm } from "react-hook-form";
import _ from "lodash";
import DialogFinish from "./components/DialogFinish";
import { LoadingButton } from "@mui/lab";
import DialogBreak from "./components/DialogBreak";
import Swal from "sweetalert2";
import QuizNavigation from "./components/QuizNavigation";

const Questions = () => {
  const [exam, setExam] = useState({});
  const [questions, setQuestions] = useState([]);
  const { examId } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const methods = useForm();
  const { getValues } = methods;
  const [pageUrl, setPageUrl] = useState("");
  const [open, setOpen] = React.useState(false);
  const [answers, setAnswers] = useState([]);
  const [openBreak, setOpenBreak] = React.useState(false);
  const ExamDetailsMemo = useMemo(
    () => <ExamDetailsBox data={questions} />,
    [questions]
  );
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const questionsPerPage = 1;

  const handleOpenBreak = () => {
    setOpenBreak(true);
  };

  const handleCloseBreak = () => {
    setOpenBreak(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/exam/get-exam/${examId}`)
      .then((res) => {
        setExam(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // GET ANSWERS
    axios
      .post(`${process.env.REACT_APP_API_URL}/exam/get-status-exam-questions`, {
        exam_id: examId,
      })
      .then((res) => {
        setAnswers(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(exam)) {
      setLoading(true);
      window.scrollTo(0, 0);
      axios
        .post(
          pageUrl ||
            `${process.env.REACT_APP_API_URL}/exam/get-exam-questions?page=${exam?.exam_attempts[0]?.page}` ||
            `${process.env.REACT_APP_API_URL}/exam/get-exam-questions?page=1`,
          {
            exam_id: examId,
            paginate: questionsPerPage,
          }
        )
        .then((res) => {
          setQuestions(res?.data?.data);
          setCurrentQuestions(
            res?.data?.data?.data?.map(
              (q, i) =>
                `q${
                  (res?.data?.data?.current_page - 1) * questionsPerPage + i + 1
                }`
            )
          );
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          window.location.href = `${window.location.origin}/exams/${examId}`;
          // navigate(`/exams/${examId}`)
          console.log(err);
        });
    }
  }, [pageUrl, exam]);

  return (
    <Box>
      
      <PageHeader exam={exam} />
      <Box sx={{ background: "#fff", padding: "50px 0" }}>
        <Container maxWidth="lg">
          <FormProvider {...methods}>
            <Stack
              direction={"row"}
              sx={{
                gap: "20px",
                flexWrap: { xs: "wrap", sm: "initial" },
                justifyContent: "center",
              }}
            >
              <Stack>
                {!_.isEmpty(questions) && ExamDetailsMemo}
                {!_.isEmpty(exam) &&
                  !_.isEmpty(answers) &&
                  !_.isEmpty(questions) && (
                    <QuizNavigation
                      answers={answers}
                      setPageUrl={setPageUrl}
                      questionsPerPage={questionsPerPage}
                      page={questions?.current_page}
                      exam={exam}
                      currentQuestions={currentQuestions}
                      getValues={getValues}
                    />
                  )}
                {exam?.is_break && (
                  <LoadingButton
                    disabled={
                      exam?.exam_attempts?.length > 0 &&
                      exam?.exam_attempts[0]?.start_break != null
                    }
                    onClick={
                      exam?.exam_attempts?.length > 0 &&
                      exam?.exam_attempts[0]?.start_break != null
                        ? undefined
                        : handleOpenBreak
                    }
                    variant="contained"
                    sx={{
                      height: "70px",
                      margin: "auto",
                      marginTop: "20px",
                      width: { xs: "min(250px, 100%)", sm: "250px" },
                    }}
                  >
                    {t("take_break")}
                  </LoadingButton>
                )}
                <DialogBreak open={openBreak} handleClose={handleCloseBreak} />
              </Stack>
              {loading ? (
                <Box sx={{ width: "100%" }}>
                  <LoadingComponent />
                </Box>
              ) : (
                <Stack sx={{ gap: "20px", width: "100%" }}>
                  {questions?.data?.length == 0 ? (
                    <Typography variant="h5" color={"GrayText"}>
                      {t("no_questions")}
                    </Typography>
                  ) : (
                    questions?.data?.map((q, i) =>
                      q?.questions[0]?.type == "choose" ? (
                        <MCQ
                          key={q?.id + "grewwa"}
                          data={q}
                          index={i}
                          total={questions?.total}
                          page={questions?.current_page}
                          questionsPerPage={questionsPerPage}
                          setAnswers={setAnswers}
                        />
                      ) : q?.questions[0]?.type == "complete" ? (
                        <Writing
                          key={q?.id + "wfewer"}
                          data={q}
                          index={i}
                          total={questions?.total}
                          page={questions?.current_page}
                          questionsPerPage={questionsPerPage}
                          setAnswers={setAnswers}
                        />
                      ) : q?.questions[0]?.type == "voice" ? (
                        <VoiceRecording
                          key={q?.id + "kgrwefo"}
                          data={q}
                          index={i}
                          total={questions?.total}
                          page={questions?.current_page}
                          questionsPerPage={questionsPerPage}
                          setAnswers={setAnswers}
                        />
                      ) : (
                        <Writing
                          key={q?.id + "iogrwefr"}
                          data={q}
                          index={i}
                          total={questions?.total}
                          page={questions?.current_page}
                          questionsPerPage={questionsPerPage}
                        />
                      )
                    )
                  )}
                  {questions?.data?.length > 0 && (
                    <Stack
                      direction={"row"}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        gap: "20px",
                      }}
                    >
                      {_.isEmpty(questions?.next_page_url) && (
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleClickOpen();
                          }}
                        >
                          {t("send_answers")}
                        </Button>
                      )}
                      <DialogFinish
                        open={open}
                        handleClose={handleClose}
                        exam={exam}
                        answers={answers}
                      />
                      <Stack
                        direction={"row"}
                        sx={{
                          gap: "9px",
                          alignItems: "center",
                          marginInlineStart: "auto",
                        }}
                      >
                        <Button
                          variant="contained"
                          disabled={
                            exam?.is_move_questions == false ||
                            _.isEmpty(questions?.prev_page_url)
                          }
                          onClick={() => {
                            if (
                              exam?.is_move_questions == true &&
                              !_.isEmpty(questions?.prev_page_url)
                            ) {
                              setPageUrl(questions?.prev_page_url);
                            }
                          }}
                        >
                          {t("previous")}
                        </Button>
                        <Typography>
                          ( {t("page")} {questions?.current_page} {t("from")}{" "}
                          {Math.ceil(questions?.total / questionsPerPage)} )
                        </Typography>
                        <Button
                          variant="contained"
                          disabled={_.isEmpty(questions?.next_page_url)}
                          onClick={() => {
                            if (exam?.is_move_questions == false) {
                              if (
                                currentQuestions?.every(
                                  (e) => !_.isEmpty(getValues(e))
                                )
                              ) {
                                if (!_.isEmpty(questions?.next_page_url)) {
                                  setPageUrl(questions?.next_page_url);
                                }
                              } else {
                                Swal.fire({
                                  html: t("answer_all_questions"),
                                  confirmButtonColor: "hsl(28 89% 63% / 1)",
                                  confirmButtonText: t("ok"),
                                  icon: "warning",
                                });
                              }
                            } else {
                              if (!_.isEmpty(questions?.next_page_url)) {
                                setPageUrl(questions?.next_page_url);
                              }
                            }
                          }}
                        >
                          {t("next")}
                        </Button>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          </FormProvider>
          {/* {isFinished && (
            <Stack
              sx={{
                background: "#0091F7",
                padding: "25px",
                width: "min(500px, 100%)",
                borderRadius: "20px",
                gap: "10px",
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto'
              }}
            >
              <img src="/assets/icons/check.svg" width={100}/>
              <Typography color={"white.main"} sx={{textAlign: 'center'}}>
                نتيجتك في مادة التربية الاسلامية لأسئلة الاختيار من متعدد هي
              </Typography>
              <Typography
                color={"primary"}
                sx={{
                  padding: "6px",
                  background: "#fff",
                  borderRadius: "10px",
                }}
              >
                30 من 40
              </Typography>
              <Typography color={"white.main"} sx={{textAlign: 'center'}}>
                سيتم تزويدك بالنتائج النهائية للامتحانات في صفحة الدرجات بعد
                التصحيح
              </Typography>
              <Button color="white" variant="outlined" sx={{borderRadius: '100vh'}} LinkComponent={Link} to={'/exams'}>العودة للصفحة الرئيسية</Button>
            </Stack>
          )} */}
        </Container>
      </Box>
    </Box>
  );
};

export default Questions;
