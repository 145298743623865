import { Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const QuizNavigation = ({
  answers,
  setPageUrl,
  questionsPerPage,
  page,
  exam,
  currentQuestions,
  getValues,
}) => {
  return (
    <Box
      sx={{
        borderRadius: "20px",
        border: "2px solid #0091F7",
        padding: "15px",
        margin: "auto",
        marginTop: "25px",
        width: { xs: "min(250px, 100%)", sm: "250px" },
      }}
    >
      <Stack direction={"row"} sx={{ gap: "14px", flexWrap: "wrap" }}>
        {answers?.map((e, i) => (
          <Box
            key={i}
            sx={{
              border:
                page == Math.ceil((i + 1) / questionsPerPage)
                  ? "2px solid #555"
                  : "1px solid #555",
              boxShadow:
                page == Math.ceil((i + 1) / questionsPerPage)
                  ? "-4px 4px 5px rgb(0, 0, 0, 0.2)"
                  : "none",
              borderRadius: "5px",
              height: "fit-content",
              cursor: exam?.is_move_questions ? "pointer" : "initial",
              // opacity: exam?.is_move_questions ? "1" : "0.5",
              userSelect: exam?.is_move_questions ? "initial" : "none",
            }}
            onClick={() => {
              if (exam?.is_move_questions) {
                setPageUrl(
                  `${
                    process.env.REACT_APP_API_URL
                  }/exam/get-exam-questions?page=${Math.ceil(
                    (i + 1) / questionsPerPage
                  )}`
                );
              }
            }}
          >
            <Typography sx={{ textAlign: "center" }}>{i + 1}</Typography>
            <Box
              sx={{
                background: e?.status_answer ? "#0091F7" : "#fff",
                width: "30px",
                height: "20px",
                borderRadius: "0 0 5px 5px",
              }}
            ></Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default QuizNavigation;
