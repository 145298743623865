import { Box, Button, Fab, Stack, Typography } from "@mui/material";
import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import i18next from "i18next";
import { useSpeechSynthesis } from "react-speech-kit";
import { Controller, useForm, useFormContext } from "react-hook-form";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
function isValidYoutubeLink(url) {
  const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  return pattern.test(url);
}
const MCQ = ({ data, index, total, page, questionsPerPage, setAnswers }) => {
  const { speak } = useSpeechSynthesis();
  const { control, watch, handleSubmit, setValue } = useFormContext();
  const { examId } = useParams();
  const { t } = useTranslation();

  const handleAnswer = (value) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/exam/user-answer-to-question`, {
        exam_id: examId,
        question_id: data?.questions_id,
        answer: value,
      })
      .then((res) => {
        setAnswers(prev => {
          const arr = prev?.map(e => e?.questions_id == data?.questions?.[0]?.id ? ({questions_id: data?.questions?.[0]?.id, status_answer: true}) : e);
          return arr;
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box
      sx={{
        borderRadius: "20px",
        border: "2px solid #0091F7",
        padding: "20px",
        width: '100%'
        // maxWidth: "540px",
        // minWidth: { xs: "initial", md: "500px" },
      }}
    >
      <Typography mb={2} sx={{ textAlign: "end", fontSize: "small" }}>
        {(page - 1) * questionsPerPage + index + 1} {t("from")} {total}
      </Typography>
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          gap: "20px",
          marginBottom: "16px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            position: "relative",
            "&::after": {
              content: "''",
              width: "100px",
              height: "1px",
              background: "#0091F7",
              position: "absolute",
              left: "0",
              bottom: "-7px",
            },
          }}
        >
          {data?.questions[0]?.title}
        </Typography>
        {i18next.language != "ar" ? (
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => speak({ text: data?.questions[0]?.title })}
            sx={{ minWidth: "40px", height: "40px", maxWidth: "40px" }}
          >
            <VolumeUpIcon />
          </Fab>
        ) : (
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {document.querySelector(`#q${(page - 1) * questionsPerPage + index + 1}mcq`).click()}}
            sx={{ minWidth: "40px", height: "40px", maxWidth: "40px" }}
          >
            <div style={{display: 'none'}} dangerouslySetInnerHTML={{__html: `
                <input id="q${(page - 1) * questionsPerPage + index + 1}mcq" onclick='responsiveVoice.speak("${data?.questions[0]?.title}", "Arabic Male");' type='button' value='► أبدا تهدر' />
            `}}></div>
            <VolumeUpIcon />
          </Fab>
        )}
        
      </Stack>
      {isValidYoutubeLink(data?.questions[0]?.youtube_link)?<Stack
        direction={"row"}
        sx={{ alignItems: "center", gap: "20px", marginBottom: "16px", justifyContent: 'center' }}
      >
    <iframe 
    width="300" 
    height="250" 
    src={`${data?.questions[0]?.youtube_link}?controls=1`} 
    allowFullScreen><iframe 
    width="300" 
    height="250" 
    src={`${data?.questions[0]?.youtube_link}?controls=1`} 
    allow="fullscreen">
</iframe>
</iframe>
      </Stack> : ""}
      <Controller
        name={`q${(page - 1) * questionsPerPage + index + 1}`}
        control={control}
        defaultValue={data?.current_answer ?? ""}
        render={({ field }) => (
          <FormControl sx={{ "& *": { fontWeight: "600" } }}>
            <RadioGroup
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value);
                handleAnswer(e.target.value);
              }}
            >
              {data?.questions[0]?.options?.map((o) => (
                <FormControlLabel
                  key={o?.title}
                  value={o?.title}
                  control={<Radio />}
                  label={o?.title}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default MCQ;
