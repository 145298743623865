import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React from "react";
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const LanguageButton = () => {
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const openMenu = Boolean(anchorElMenu);
  const handleClick = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };
  return (
    <div>
      <Button
        variant="outlined"
        color="white"
        id="basic-button"
        aria-controls={openMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        sx={{ textTransform: "capitalize", minWidth: 'fit-content', padding: '5px 10px' }}
        onClick={handleClick}
      >
        <LanguageIcon sx={{marginInlineEnd: '4px'}}/>
        {i18next.language == "ar" ? (
          <Stack direction="row" sx={{ gap: "5px" }}>
            {/* <img src="/assets/icons/sa.png" width={26} />{" "} */}
            <Typography>العربية</Typography>{" "}
          </Stack>
        ) : i18next.language == "en" ? (
          <Stack direction="row" sx={{ gap: "5px" }}>
            {/* <img src="/assets/icons/uk.png" width={26} />{" "} */}
            <Typography>English</Typography>{" "}
          </Stack>
        ) : (
          "Language"
        )}
        <KeyboardArrowDownIcon sx={{fontSize: '1.2rem', marginInlineStart: '3px'}}/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            i18next.changeLanguage("ar");
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <img src="/assets/icons/sa.png" width={26} />
          </ListItemIcon>
          <ListItemText>العربية</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            i18next.changeLanguage("en");
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <img src="/assets/icons/uk.png" width={26} />
          </ListItemIcon>
          <ListItemText>English</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageButton;
