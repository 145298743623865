import {
  Box,
  Container,
  Pagination,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "./components/PageHeader";
import NavigationBox from "../utils/NavigationBox";
import SubjectCard from "./components/SubjectCard";
import axios from "axios";
import LoadingComponent from "../utils/LoadingComponent";
import { useTranslation } from "react-i18next";

const Exams = () => {
  const [loading, setLoading] = useState(true);
  const [exams, setExams] = useState([]);
  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}/exam`)
      .then((res) => {
        setExams(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Box>
    
      <PageHeader />
      <Box sx={{ background: "#fff", padding: "50px 0" }}>
        <Container maxWidth="lg">
          <Stack
            direction={"row"}
            sx={{ gap: "20px", flexWrap: { xs: "wrap", sm: "initial" } }}
          >
            <NavigationBox />
            {loading ? (
              <Box sx={{ marginInlineStart: "100px" }}>
                <LoadingComponent />
              </Box>
            ) : (
              <Box sx={{width: '100%'}}>
                <Stack direction={"row"} sx={{ gap: "30px", flexWrap: "wrap" }}>
                  {exams?.length == 0 ? (
                    <Typography variant="h5" color={"GrayText"}>
                      {t("no_exams")}
                    </Typography>
                  ) : (
                    exams?.slice((page-1)*10, page * 10)?.map((e, i) => <SubjectCard key={i} i={i} data={e} />)
                  )}
                </Stack>
                {exams?.length > 0 && <Pagination count={Math.ceil(exams.length/10)} page={page} onChange={handleChange} color="primary" sx={{display: 'grid', placeItems: 'center', marginTop: '30px'}}/>}
              </Box>
            )}
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default Exams;
