import React from "react";
import "@splidejs/react-splide/css";
import {
  Button, Stack,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FirstSlide = () => {
  const { t } = useTranslation();
  return (
    <Stack sx={{ color: "#fff", gap: "25px" }}>
      {/* <Typography variant="h1" sx={{ fontWeight: "bold", fontSize: "2rem" }}>
        جمعية المحافظة على القرآن الكريم - فرع الزرقاء الثاني
      </Typography> */}
      <Typography sx={{ fontWeight: "100", maxWidth: "600px", fontSize: '1.2rem' }}>
      <b>
        {t('project_title_desc')}
        </b>  
      </Typography>
      <Typography sx={{ fontWeight: "100", maxWidth: "600px", fontSize: '1.2rem' }}>
        {t('project_desc')}
      </Typography>
      <Stack direction={"row"} sx={{ gap: "17px" }}>
        <Button
          variant="contained"
          sx={{ borderRadius: "100vh", width: "160px" }}
          LinkComponent={Link}
          to={'/about-us'}
        >
          {t('about_us')}
        </Button>
        <Button
          variant="contained"
          color="white"
          LinkComponent={Link}
          to={'/contact-us'}
          sx={{
            borderRadius: "100vh",
            color: "#000",
            width: "160px",
          }}
        >
          {t('contact_us')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default FirstSlide;
