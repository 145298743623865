import { Box, Chip, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SubjectCard = ({ i, data }) => {
  const currentUser = useSelector(s=>s.user).currentUser
  
  return (
    <Link
      to={`/exams/${data?.id}`}
      className="card-animation"
      style={{ animationDelay: `0.${i}s`, height: "fit-content" }}
    >
      <Stack
        sx={{
          padding: "15px",
          borderRadius: "10px",
          border: "1px solid rgb(0, 0, 0, 18%)",
          aspectRatio: "1",
          width: "150px",
          height: '178.6px',
          justifyContent: "space-around",
        }}
      >
        {currentUser?.is_attempting == true && data?.id == currentUser?.current_exam_id && <Chip
          color="primary"
          label={currentUser?.is_attempting == true && data?.id == currentUser?.current_exam_id ? t('in_progress') : ''}
          size="small"
          sx={{
            width: "fit-content",
            fontSize: "0.7rem",
            alignSelf: "flex-end",
            transform: "translate(6px, -8px) !important",
            marginBottom: '3px'
          }}
        />}
        <Box
          sx={{
            padding: "15px",
            borderRadius: "50%",
            background: "#0091F7",
            display: "grid",
            placeItems: "center",
            marginBottom: "7px !important",
            width: "65px",
            height: "65px",
            margin: "0 auto",
          }}
        >
          <img src="/assets/icons/file.svg" width={20} />
        </Box>
        <Typography sx={{ textAlign: "center", fontWeight: "700" }} className="text-ellipsis-2">
          {data?.title}
        </Typography>
      </Stack>
    </Link>
  );
};

export default SubjectCard;
