import { Pause, PlayArrow, Stop, VolumeUp } from "@mui/icons-material";
import { Box, Button, Fab, IconButton, Stack, Typography } from "@mui/material";
import axios from "axios";
import i18next from "i18next";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useReactMediaRecorder } from "react-media-recorder";
import { useParams } from "react-router-dom";
import { useSpeechSynthesis } from "react-speech-kit";
function isValidYoutubeLink(url) {
  const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  return pattern.test(url);
}
const VoiceRecording = ({ data, index, total, page, questionsPerPage, setAnswers }) => {
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({});
  const { t } = useTranslation();
  const { speak } = useSpeechSynthesis();
  const { examId } = useParams();
  const { control, watch, handleSubmit, setValue } = useFormContext();

  useEffect(() => {
    if (mediaBlobUrl) {
      async function handleAudio(){
        const formData = new FormData();
        formData.append("exam_id", examId);
        formData.append("question_id", data?.questions_id);
        const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
        const audioFile = new File([audioBlob], 'voice.mp3', { type: 'audio/mp3' });
        formData.append("answer", audioFile);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/exam/user-answer-to-question`,
            formData
          )
          .then((res) => {
            setAnswers(prev => {
              const arr = prev?.map(e => e?.questions_id == data?.questions?.[0]?.id ? ({questions_id: data?.questions?.[0]?.id, status_answer: true}) : e);
              return arr;
            })
          })
          .catch((err) => {
            console.log(err);
          });
      }
      handleAudio();
    }
  }, [mediaBlobUrl]);

  useEffect(()=>{
    if(data?.current_answer){
      setValue(`q${(page - 1) * questionsPerPage + index + 1}`, 'answered')
    }
  },[])

  return (
    <Box
      sx={{
        borderRadius: "20px",
        border: "2px solid #0091F7",
        padding: "20px",
        width: '100%'
        // maxWidth: "540px",
        // minWidth: { xs: "initial", md: "500px" },
      }}
    >
      <Typography mb={2} sx={{ textAlign: "end", fontSize: "small" }}>
      {(page - 1) * questionsPerPage + index + 1} {t("from")} {total}
      </Typography>
      <Stack
        direction={"row"}
        sx={{ alignItems: "center", gap: "20px", marginBottom: "24px", justifyContent: 'space-between' }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            position: "relative",
            "&::after": {
              content: "''",
              width: "100px",
              height: "1px",
              background: "#0091F7",
              position: "absolute",
              left: "0",
              bottom: "-7px",
            },
          }}
        >
          {data?.questions[0]?.title}
        </Typography>
        {i18next.language != "ar" ? (
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => speak({ text: data?.questions[0]?.title })}
            sx={{ minWidth: "40px", height: "40px", maxWidth: "40px" }}
          >
            <VolumeUp />
          </Fab>
        ) : (
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {document.querySelector(`#q${(page - 1) * questionsPerPage + index + 1}vr`).click()}}
            sx={{ minWidth: "40px", height: "40px", maxWidth: "40px" }}
          >
            <div style={{display: 'none'}} dangerouslySetInnerHTML={{__html: `
                <input id="q${(page - 1) * questionsPerPage + index + 1}vr" onclick='responsiveVoice.speak("${data?.questions[0]?.title}", "Arabic Male");' type='button' value='► أبدا تهدر' />
            `}}></div>
            <VolumeUp />
          </Fab>
        )}
      </Stack>
      {isValidYoutubeLink(data?.questions[0]?.youtube_link)?<Stack
        direction={"row"}
        sx={{ alignItems: "center", gap: "20px", marginBottom: "16px", justifyContent: 'center' }}
      >
    <iframe 
    width="300" 
    height="250" 
    src={`${data?.questions[0]?.youtube_link}?controls=1`} 
    allowFullScreen><iframe 
    width="300" 
    height="250" 
    src={`${data?.questions[0]?.youtube_link}?controls=1`} 
    allow="fullscreen">
</iframe>
</iframe>
      </Stack> : ""}
      <div>
        <audio
          style={{ width: "min(300px, 100%)" }}
          src={mediaBlobUrl ?? data?.current_answer}
          controls
        />
        <Typography mb={1}>{t(status)}</Typography>
        {/* <IconButton color="success"></IconButton> */}
        {(status == "idle" || status == "stopped") && (
          <Button
            sx={{ marginInline: "5px" }}
            onClick={startRecording}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<PlayArrow />}
          >
            {t("record")}
          </Button>
        )}
        {status == "recording" && (
          <Button
            sx={{ marginInline: "5px" }}
            onClick={pauseRecording}
            size="small"
            variant="contained"
            color="warning"
            startIcon={<Pause />}
          >
            {t("pause")}
          </Button>
        )}
        {status == "paused" && (
          <Button
            sx={{ marginInline: "5px" }}
            onClick={resumeRecording}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<PlayArrow />}
          >
            {t("resume")}
          </Button>
        )}
        {(status == "recording" || status == "paused") && (
          <Button
            sx={{ marginInline: "5px" }}
            onClick={() => {
              stopRecording();
              setValue(`q${(page - 1) * questionsPerPage + index + 1}`, 'answered')
            }}
            size="small"
            variant="contained"
            color="error"
            startIcon={<Stop />}
          >
            {t("stop")}
          </Button>
        )}
      </div>
    </Box>
  );
};

export default VoiceRecording;
