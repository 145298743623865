import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "./components/PageHeader";
import NavigationBox from "../utils/NavigationBox";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../utils/LoadingComponent";
import axios from "axios";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/userSilce";

function addMinutes(date, minutes) {
  date.setMinutes(date.getMinutes() + minutes);

  return date;
}

const CompletedExamDetails = () => {
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState({});
  const { examId } = useParams();
  const { t } = useTranslation();
  const [loadingEnter, setLoadingEnter] = useState(false);
  const navigate = useNavigate();
  const [isBreak, setIsBreak] = useState(false);
  const currentUser = useSelector((s) => s.user).currentUser;
  const dispatch = useDispatch();
  console.log(currentUser);
  const [examQuestions, setExamQuestions] = useState([]); 
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? t("pm") : t("am");
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
    
  } 
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/exam/get-exam/${examId}`)
      .then((res) => {
        setExam(res?.data?.data);

        const data = res?.data?.data;

        if (
          data?.exam_attempts[0]?.start_break != null &&
          data?.exam_attempts[0]?.end_break == null
        ) {
          const result = addMinutes(
            new Date(data?.exam_attempts[0]?.start_break),
            data?.duration_break ?? 0
          );
          if (result != null && new Date() < result) {
            setIsBreak(true);
          } else {
            setIsBreak(false);
          }
        } else {
          setIsBreak(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
 
  useEffect(() => {
    const fetchExamQuestions = async () => {
      try {
        const response = await axios({
          method: 'post',
          url: 'https://api.step-ar.site/api/v1/user/exam/get-exam-questions?page=1',
          headers: {
            'Authorization': `Bearer ${currentUser?.token}`, // Ensure token is passed here
            'Accept': 'application/json',
          },
          data: new URLSearchParams({
            'exam_id': exam?.id.toString(),
            'paginate': '2'
          })
        });

        const data = response?.data?.data;
        setExamQuestions(data);

        // Logic for determining break status from examQuestions

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchExamQuestions();
  }, [exam?.id, currentUser?.token]);

  // check exam id
  console.log("what is my id",exam?.id);
  const handleEnterExam = () => {
    setLoadingEnter(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/exam/enter-exam`, {
        exam_id: exam?.id,
      })
      .then(() => {
        navigate(`/exams/${examId}/questions`);
        setLoadingEnter(false);
        axios
          .get(`${process.env.REACT_APP_API_URL}/profile`)
          .then((response) => {
            dispatch(
              login({
                token: currentUser?.token,
                user: { ...response?.data?.data, token: currentUser?.token },
              })
            );
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          // title: t('success'),
          html: err?.response?.data?.message ?? t("smth_went_wrong"),
          confirmButtonColor: "#d33",
          confirmButtonText: t("ok"),
          icon: "error",
        });
        setLoadingEnter(false);
      });
  };

  const handleResumeExam = () => {
    setLoadingEnter(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/exam/end-break`, {
        exam_id: examId,
      })
      .then((res) => {
        navigate(`/exams/${examId}/questions`);
        setLoadingEnter(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingEnter(false);
      });
  };
  const handleReviewExam = (examId) => {
    navigate(`/exams/${examId}/review`);
  };
  return (
    <Box>
      <PageHeader data={exam} />
      <Box sx={{ background: "#fff", padding: "50px 0" }}>
        <Container maxWidth="lg">
          <Stack
            direction={"row"}
            sx={{ gap: "20px", flexWrap: { xs: "wrap", sm: "initial" } }}
          >
            <NavigationBox />
            {loading ? (
              <Box sx={{ marginInlineStart: "100px" }}>
                <LoadingComponent />
              </Box>
            ) : (
              <Stack sx={{ gap: "13px" }}>
                <Typography
                  fontSize={"small"}
                  color={"primary"}
                  sx={{ fontWeight: "700" }}
                >
                  {exam?.category_name}
                </Typography>
                <Typography sx={{ fontWeight: "700" }}>
                  {exam?.title}
                </Typography>
                <Stack
                  direction={"row"}
                  sx={{ gap: "15px", flexWrap: "wrap", maxWidth: "700px" }}
                >
                  <Stack
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid rgb(0, 0, 0, 18%)",
                      padding: "15px",
                      width: "220px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      minHeight: "100px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "700", textAlign: "center" }}>
                      {t("exam_date")}
                    </Typography>
                    <Typography color={"primary"} sx={{ textAlign: "center", fontSize: '0.9rem' }}>
                      {t('start')} {exam?.start_at?.split(" ")[0]} , {formatAMPM(new Date(exam?.start_at))}
                    </Typography>
                    <Typography color={"primary"} sx={{ textAlign: "center", fontSize: '0.9rem' }}>
                      {t('end')} {exam?.end_at?.split(" ")[0]} , {formatAMPM(new Date(exam?.end_at))}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid rgb(0, 0, 0, 18%)",
                      padding: "15px",
                      width: "220px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      minHeight: "100px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "700", textAlign: "center" }}>
                      {t("exam_duration")}
                    </Typography>
                    <Typography color={"primary"} sx={{ textAlign: "center" }}>
                      {exam?.duration} {t("minute")}
                    </Typography>
                  </Stack>
                   
                  <Stack
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid rgb(0, 0, 0, 18%)",
                      padding: "15px",
                      width: "220px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      minHeight: "100px",
                    }}
                  >
                    <Typography sx={{ fontWeight: "700", textAlign: "center" }}>
                      {t("exam_score")}
                    </Typography>
                    <Typography color={"primary"} sx={{ textAlign: "center" }}>
                      {exam?.correction_status
                        ? `${exam?.user_score} ${t("from")} ${exam?.exam_score}`
                        : t("no_score")}
                    </Typography>
                  </Stack>
                  <LoadingButton
                    loading={loadingEnter}
                    variant="contained"
                    disabled={!exam?.is_available && !exam?.is_finished}
                    color={
                      exam?.is_finished ||
                      exam?.remaining_time_in_milliseconds == 0 ||
                      (currentUser?.is_attempting == true &&
                        exam?.id != currentUser?.current_exam_id)
                        ? "grey"
                        : "primary"
                    }
                    sx={{
                      width: "220px",
                      minHeight: "100px",
                      borderRadius: "10px",
                      fontWeight: "700",
                    }}
                  onClick={() => handleReviewExam(exam?.id)}
                  >
                   مراجعة الإجابات
                  </LoadingButton>
                  <LoadingButton
                    loading={loadingEnter}
                    variant="contained"
                    disabled={!exam?.is_available && !exam?.is_finished}
                    color={
                      exam?.is_finished ||
                      exam?.remaining_time_in_milliseconds == 0 ||
                      (currentUser?.is_attempting == true &&
                        exam?.id != currentUser?.current_exam_id)
                        ? "error"
                        : "primary"
                    }
                    sx={{
                      width: "220px",
                      minHeight: "100px",
                      borderRadius: "10px",
                      fontWeight: "700",
                    }}
                    onClick={
                      !exam?.is_finished &&
                      exam?.is_available &&
                      !isBreak &&
                      exam?.remaining_time_in_milliseconds != 0 &&
                      (currentUser?.is_attempting == false ||
                        exam?.id == currentUser?.current_exam_id)
                        ? handleEnterExam
                        : exam?.remaining_time_in_milliseconds == 0
                        ? undefined
                        : isBreak
                        ? handleResumeExam
                        : undefined
                    }
                  >
                    {/* <Typography
                        color={"white.main"}
                        sx={{ fontWeight: "700", textAlign: "center" }}
                      > */}
                    {exam?.is_finished
                      ? t("exam_finished")
                      : !exam?.is_available
                      ? t("exam_not_valid")
                      : exam?.remaining_time_in_milliseconds == 0
                      ? t("time_up")
                      : currentUser?.is_attempting == true &&
                        exam?.id != currentUser?.current_exam_id
                      ? t("you_in_exam")
                      : isBreak
                      ? t("resume_exam")
                      : t("enter_exam")}
                    {/* </Typography> */}
                  </LoadingButton>
                  {/* </Link> */}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Container>
      </Box>
    </Box>
  );
};

export default CompletedExamDetails;
