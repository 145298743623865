import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";
import { t } from "i18next";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: "100vh",
    padding: "10px",
    background: "#EFEFEF",
  },
  "& input": { height: "7px" },
  "& fieldset": { border: "none" },
});

const StyledFormControl = styled(FormControl)({
  "& .MuiInputBase-root": {
    borderRadius: "100vh",
    padding: "2px",
    background: "#EFEFEF",
  },
  "& fieldset": { border: "none" },
});

const ContactUs = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const errMessage = useRef(null);
  const navigate = useNavigate();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/help_and_support_center/store`, {
        name: data?.username,
        phone: data?.phone,
        type: data?.subject_classification,
        topic: data?.subject,
      })
      .then((res) => {
        setLoading(false);
        navigate('/')
        Swal.fire({
          html: t("sent_successfully"),
          confirmButtonColor: "#0091F7",
          confirmButtonText: t("ok"),
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err)
        setLoading(false);
        errMessage.current.textContent = err?.response?.data?.message ?? t("smth_went_wrong");
      });
  };

  return (
    <Box>
      <Container
        maxWidth="lg"
        sx={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: "20px",
            maxWidth: "600px",
            margin: "auto",
            borderRadius: "15px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography mb={5} variant="h4" sx={{ fontWeight: "bold" }}>
              {t("contact_us")}
            </Typography>
            <Stack
              direction={"row"}
              sx={{
                width: "100%",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Box mb={3} sx={{ width: {xs: '100%', sm: "min(270px, 100%)"} }}>
                <Typography mb={1} color={"GrayText"}>
                  {t("name")}*
                </Typography>
                <Controller
                  name="username"
                  control={control}
                  defaultValue={""}
                  rules={{ required: t("username_required") }}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      error={!!errors?.username}
                      helperText={errors?.username?.message}
                      placeholder={t("name")}
                      size="small"
                    />
                  )}
                />
              </Box>
              <Box mb={3} sx={{ width: {xs: '100%', sm: "min(270px, 100%)"} }}>
                <Typography mb={1} color={"GrayText"}>
                  {t("phone_number")}*
                </Typography>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue={""}
                  rules={{ required: t("phone_number_required") }}
                  render={({ field }) => (
                    <StyledTextField
                      {...field}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          field.onChange(e.target.value);
                        }
                      }}
                      error={!!errors?.phone}
                      helperText={errors?.phone?.message}
                      placeholder={t("phone_number")}
                      size="small"
                    />
                  )}
                />
              </Box>
            </Stack>
            <Box sx={{ width: "100%", marginBottom: "24px" }}>
              <Typography mb={1} color={"GrayText"}>
                {t("subject_classification")}*
              </Typography>
              <Controller
                name="subject_classification"
                control={control}
                defaultValue={""}
                rules={{ required: t("subject_classification_required") }}
                render={({ field }) => (
                  <StyledFormControl fullWidth size="small">
                    <Select
                      {...field}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                    >
                      <MenuItem value={"technical_problem"}>
                        {t("technical_problem")}
                      </MenuItem>
                      <MenuItem value={"suggestion"}>
                        {t("suggestion")}
                      </MenuItem>
                      <MenuItem value={"question"}>{t("questioning")}</MenuItem>
                      <MenuItem value={"other"}>{t("other")}</MenuItem>
                    </Select>
                    <Typography
                      sx={{ marginInlineStart: "12px" }}
                      color={"error"}
                      fontSize={"small"}
                    >
                      {errors?.subject_classification?.message}
                    </Typography>
                  </StyledFormControl>
                )}
              />
            </Box>
            <Box sx={{ width: "100%", marginBottom: "24px" }}>
              <Typography mb={1} color={"GrayText"}>
                {t("subject")}*
              </Typography>
              <Controller
                name="subject"
                control={control}
                defaultValue={""}
                rules={{ required: t("subject_required") }}
                render={({ field }) => (
                  <StyledTextField
                    {...field}
                    multiline
                    rows={5}
                    sx={{ "& .MuiInputBase-root": { borderRadius: "10px" } }}
                    error={!!errors?.subject}
                    helperText={errors?.subject?.message}
                    placeholder={t("subject")}
                    size="small"
                  />
                )}
              />
            </Box>
            <Typography
              mb={2}
              sx={{ fontSize: "14px" }}
              color={"error"}
              ref={errMessage}
            ></Typography>
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              sx={{ marginInlineStart: "auto", display: "block" }}
            >
              {t("send")}
            </LoadingButton>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default ContactUs;
