import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { t } from "i18next";
import { Link } from "react-router-dom";

const NewsCard = ({data}) => {
  return (
    <Card sx={{ width: 'min(100%, 345px)', margin: 'auto' }}>
      <CardMedia
        sx={{ height: 140 }}
        image={data?.image}
        title={data?.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" className="text-ellipsis-2">
          {data?.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" className="text-ellipsis-3">
          {data?.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" LinkComponent={Link} to={`/news/${data?.id}`}>{t('read_more')}</Button>
      </CardActions>
    </Card>
  );
};

export default NewsCard;
