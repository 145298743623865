import { Box, Container, Stack, TextField, Typography, styled } from "@mui/material";
import React, { useRef, useState } from "react";
import PageHeader from "./components/PageHeader";
import NavigationBox from "../utils/NavigationBox";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/userSilce";
import { useEffect } from "react";

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: "100vh",
    padding: "8px 6px",
    background: "#EFEFEF",
  },
  "& input": { height: "7px" },
  "& fieldset": { border: "none" },
});

const ChangePassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const errMessage = useRef(null)
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user).currentUser
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset
  } = useForm({mode: "onChange"});

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const onSubmit = (data) => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_URL}/profile/change-password`, {
      old_password: data?.current_password,
      new_password: data?.new_password
    }).then(res => {
      setLoading(false);
      reset({});
      errMessage.current.textContent = '';
      dispatch(
        login({
          token: res?.data?.data?.token,
          user: { ...currentUser },
        })
      );
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res?.data?.data?.token}`;
      Swal.fire({
        html: t("password_changed"),
        confirmButtonColor: '#0091F7',
        confirmButtonText: t('ok'),
        icon: 'success'
      })
    }).catch(err => {
      console.log(err);
      setLoading(false);
      errMessage.current.textContent = err?.response?.data?.message ?? t('smth_went_wrong');
    })
  };

  return (
    <Box>
      <PageHeader />
      <Box sx={{ background: "#fff", padding: "50px 0" }}>
        <Container maxWidth="lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction={"row"}
              sx={{ gap: "20px", flexWrap: { xs: "wrap", sm: "initial" } }}
            >
              <NavigationBox />
              <Stack>
                <Box mb={2}>
                  <Typography color={"GrayText"} sx={{marginBottom: '3px'}}>
                    {t("current_password")}*
                  </Typography>
                  <Controller
                    name="current_password"
                    defaultValue={""}
                    control={control}
                    rules={{ required: t("current_password_required") }}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        type="password"
                        error={!!errors?.current_password}
                        helperText={errors?.current_password?.message}
                        placeholder={t("current_password")}
                        size="small"
                        sx={{width: {xs: '250px', sm: '300px'}}}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Typography color={"GrayText"} sx={{marginBottom: '3px'}}>
                    {t("new_password")}*
                  </Typography>
                  <Controller
                    name="new_password"
                    defaultValue={""}
                    control={control}
                    rules={{ required: t("new_password_required")}}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        type="password"
                        error={!!errors?.new_password}
                        helperText={errors?.new_password?.message}
                        placeholder={t("new_password")}
                        size="small"
                        sx={{width: {xs: '250px', sm: '300px'}}}
                      />
                    )}
                  />
                </Box>
                <Box mb={2}>
                  <Typography color={"GrayText"} sx={{marginBottom: '3px'}}>
                    {t("confirm_password")}*
                  </Typography>
                  <Controller
                    name="confirm_password"
                    defaultValue={""}
                    control={control}
                    rules={{ required: t("confirm_password_required"), validate: (value) => {
                      if(watch('new_password') != value){
                        return t('password_mismatch')
                      }
                    } }}
                    render={({ field }) => (
                      <StyledTextField
                        {...field}
                        type="password"
                        error={!!errors?.confirm_password}
                        helperText={errors?.confirm_password?.message}
                        placeholder={t("confirm_password")}
                        size="small"
                        sx={{width: {xs: '250px', sm: '300px'}}}
                      />
                    )}
                  />
                </Box>
                <Typography
                    mb={1}
                    sx={{ fontSize: "14px" }}
                    color={"error"}
                    ref={errMessage}
                  ></Typography>
                <LoadingButton loading={loading} type="submit" variant="contained" sx={{width: 'fit-content'}}>{t('save')}</LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Container>
      </Box>
    </Box>
  );
};

export default ChangePassword;
