import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const DialogBreak = ({open, handleClose}) => {
  const { t } = useTranslation();
  const [loadingBreak, setLoadingBreak] = useState(false);
  const navigate = useNavigate();
  const { examId } = useParams();

  const handleBreak = () => {
    setLoadingBreak(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/exam/take-break`, {
        exam_id: examId,
        remaining_time: 1,
      })
      .then((res) => {
        navigate(`/exams/${examId}`);
        setLoadingBreak(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{'& .MuiPaper-root': {width: '340px'}}}
    >
      <DialogTitle id="alert-dialog-title">
        {t('take_break')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('wanna_take_break')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="gray">{t('no')}</Button>
        <LoadingButton loading={loadingBreak} onClick={handleBreak} autoFocus variant="contained">
          {t('yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBreak;
