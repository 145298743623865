import { useEffect } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { Container, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { arSA } from "@mui/material/locale";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { enUS } from "@mui/x-data-grid";
import Layout from "./main/layout/Layout";
import SignIn from "./main/sign-in/SignIn";
import SignUp from "./main/sign-up/SignUp";
import Exams from "./main/exams/Exams";
import ExamDetails from "./main/exam-details/ExamDetails";
import Questions from "./main/questions/Questions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { login, logout } from "./redux/userSilce";
import ChangePassword from "./main/change-password/ChangePassword";
import Profile from "./main/profile/Profile";
import Home from "./main/home-pages/home/Home";
import CompletedExams from "./main/completed-exams/CompletedExams";
import CompletedExamDetails from "./main/completed-exam-details/CompletedExamDetails";
import ContactUs from "./main/home-pages/contact-us/ContactUs";
import PrivacyPolicy from "./main/home-pages/privacy-policy/PrivacyPolicy";
import Testimonials from "./main/home-pages/testimonials/Testimonials";
import Album from "./main/home-pages/album/Album";
import News from "./main/home-pages/news/News";
import SingleNews from "./main/home-pages/news/SingleNews";
import AboutUs from "./main/home-pages/about-us/AboutUs";
import ReviewExamDetails from './main/review-exams-details/ReviewExamDetails';
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const cache = createCache({ key: "css" });

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.user);
  axios.defaults.headers.common['Authorization'] = `Bearer ${currentUser.token}`;
  axios.defaults.headers.common['Accept-Language'] = i18next.language;

  useEffect(()=>{
    if(currentUser?.token){
      axios
      .get(`${process.env.REACT_APP_API_URL}/profile`)
      .then((response) => {
        dispatch(
          login({
            token: currentUser?.token,
            user: { ...response?.data?.data, token: currentUser?.token },
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },[])

  const theme = createTheme(
    {
      ...(i18next.language == "ar" && { direction: "rtl" }),
      palette: {
        primary: {
          main: "#0091F7",
          contrastText: '#fff'
        },
        gray: {
          main: "#757575",
        },
        white: {
          main: "#fff",
        },
        backgroundGray: {
          main: "#F8F8FA",
        },
        lightGray: {
          main: "#878A99",
        },
        black: {
          main: '#000'
        },
        boxBackground: {
          main: '#1b1b29',
          contrastText: '#fff'
        }
      },
    },
    i18next.language == "ar" ? arSA : enUS
  );

  const page404 = (
    <h1 style={{ textAlign: "center", padding: "250px" }}>
      404 | {t("page_not_found")}
    </h1>
  );
  const page404white = (
    <h1 style={{ textAlign: "center", padding: "250px", color: '#fff' }}>
      404 | {t("page_not_found")}
    </h1>
  );

  useEffect(() => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (config) {
        // Do something with response
        return config;
      },
      function (error) {
        if (error?.response?.status == 401) {
          dispatch(logout());
          window.location.href = `${window.location.origin}/sign-in`;
          delete axios.defaults.headers.common["Authorization"];
          axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`);
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (i18next.language == "ar") {
      document.dir = "rtl";
    } else {
      document.dir = "ltr";
    }
  }, [i18next.language]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout/>,
      children: [
        {
          index: true,
          element: <Home/>
        },
        {
          path: 'about-us',
          element: <AboutUs/>
        },
        {
          path: 'contact-us',
          element: <ContactUs/>
        },
        {
          path: 'privacy-usage-policy',
          element: <PrivacyPolicy/>
        },
        {
          path: 'album',
          element: <Album/>
        },
        {
          path: 'news',
          element: <News/>
        },
        {
          path: 'news/:newsId',
          element: <SingleNews/>
        },
        // {
        //   path: 'testimonials',
        //   element: <Testimonials/>
        // },
        {
          path: 'sign-in',
          element: currentUser.token ? <Navigate to='/'/> : <SignIn/>
        },
        {
          path: 'sign-up',
          element: currentUser.token ? <Navigate to='/'/> : <SignUp/>
        },
        {
          path: 'exams',
          element: currentUser.token ? <Exams/> : <Navigate to='/sign-in'/>
        },
        {
          path: 'exams/:examId',
          element: currentUser.token ? <ExamDetails/> : <Navigate to='/sign-in'/>
        },
        {
          path: 'exams/:examId/questions',
          element: currentUser.token ? <Questions/> : <Navigate to='/sign-in'/>
        },
        {
          path: 'completed-exams',
          element: currentUser.token ? <CompletedExams/> : <Navigate to='/sign-in'/>
        },
        {
          path: 'completed-exams/:examId',
          element: currentUser.token ? <CompletedExamDetails/> : <Navigate to='/sign-in'/>
        },
        {
          path: 'change-password',
          element: currentUser.token ? <ChangePassword/> : <Navigate to='/sign-in'/>
        },
        {
          path: 'profile',
          element: currentUser.token ? <Profile/> : <Navigate to='/sign-in'/>
        },
       { path:'/exams/:examId/review',
        element: currentUser.token ? <ReviewExamDetails/> : <Navigate to='/sign-in'/>
       },{
          path: "*",
          element: page404white,
        },
      ]
    },
    {
      path: "*",
      element: page404,
    },
  ]);

  return (
    <CacheProvider
      {...(i18next.language == "ar" ? { value: cacheRtl } : { value: cache })}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
