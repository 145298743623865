import React, { useEffect, useRef, useState } from "react";
import PageHeader from "./components/PageHeader";
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import NavigationBox from "../utils/NavigationBox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { AddPhotoAlternate } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import LoadingComponent from "../utils/LoadingComponent";
import Swal from "sweetalert2";
import { login } from "../../redux/userSilce";

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: "100vh",
    padding: "10px",
    background: "#EFEFEF",
  },
  "& input": { height: "7px" },
  "& fieldset": { border: "none" },
});

const StyledFormControl = styled(FormControl)({
  "& .MuiInputBase-root": {
    borderRadius: "100vh",
    padding: "2px",
    background: "#EFEFEF",
  },
  "& fieldset": { border: "none" },
});

const Profile = () => {
  const { t } = useTranslation();
  // const [qualifications, setQualifications] = useState([]);
  // const [branches, setBranches] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const errMessage = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const inputImage = useRef();
  const currentUser = useSelector((state) => state.user).currentUser;
  const token = useSelector((state) => state.user).token;
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({mode: 'onChange'});

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_URL}/profile/get-qualification-and-branch`
  //     )
  //     .then((res) => {
  //       setQualifications(res?.data?.data?.qualification);
  //       setBranches(res?.data?.data?.branches);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // }, []);
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const onSubmit = (data) => {
    setLoadingSubmit(true);

    const formData = new FormData();
    Object.keys(data)?.forEach((key) => {
      if (key != "branches_ids" && key != 'username') {
        formData.append(key, data[key]);
      }
    });
    data?.branches_ids?.forEach((e, i) => {
      formData.append(`branches_ids[${i}]`, e);
    });
    image && formData.append("image", image);

    axios
      .post(`${process.env.REACT_APP_API_URL}/profile/update`, formData)
      .then((res) => {
        setLoadingSubmit(false);
        dispatch(
          login({
            token: token,
            user: { ...res?.data?.data },
          })
        );
        errMessage.current.textContent = "";
        Swal.fire({
          html: t("profile_changed"),
          confirmButtonColor: "#0091F7",
          confirmButtonText: t("ok"),
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        errMessage.current.textContent =
          err?.response?.data?.message ?? t("smth_went_wrong");
        setLoadingSubmit(false);
      });
  };

  return (
    <Box>
      <PageHeader />
      <Box sx={{ background: "#fff", padding: "50px 0" }}>
        <Container maxWidth="lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              direction={"row"}
              sx={{ gap: "20px", flexWrap: { xs: "wrap", sm: "initial" } }}
            >
              <NavigationBox />
              <Paper
                elevation={2}
                sx={{
                  borderRadius: "20px",
                  padding: "25px 25px 35px",
                  width: "min(700px, 100%)",
                  // margin: "auto",
                }}
              >
                <Stack
                  direction={"row"}
                  sx={{
                    alignItems: "flex-end",
                    marginBottom: "30px",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "150px",
                      height: "150px",
                      boxShadow: "-5px 5px 5px rgb(0, 0, 0, 0.1)",
                    }}
                    src={
                      (image && URL.createObjectURL(image)) ??
                      currentUser?.image
                    }
                    alt="profile picture"
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={inputImage}
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setValue("image", e.target.files[0]);
                    }}
                  />
                  <IconButton
                    color="primary"
                    onClick={() => {
                      inputImage.current.click();
                    }}
                    sx={{ transform: "translateX(-10px)" }}
                  >
                    <AddPhotoAlternate fontSize="large" />
                  </IconButton>
                </Stack>
                <Stack
                  mb={3}
                  direction={"row"}
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ width: "min(310px, 100%)" }}>
                    <Typography mb={1} color={"GrayText"}>
                      {t("first_name")}*
                    </Typography>
                    <Controller
                      name="first_name"
                      control={control}
                      defaultValue={currentUser?.first_name}
                      rules={{ required: t("first_name_required") }}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          error={!!errors?.first_name}
                          helperText={errors?.first_name?.message}
                          placeholder={t("first_name")}
                          size="small"
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ width: "min(310px, 100%)" }}>
                    <Typography mb={1} color={"GrayText"}>
                      {t("last_name")}*
                    </Typography>
                    <Controller
                      name="last_name"
                      control={control}
                      defaultValue={currentUser?.last_name}
                      rules={{ required: t("last_name_required") }}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          error={!!errors?.last_name}
                          helperText={errors?.last_name?.message}
                          placeholder={t("last_name")}
                          size="small"
                        />
                      )}
                    />
                  </Box>
                </Stack>
             
                <Stack
                  mb={3}
                  direction={"row"}
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ width: "min(310px, 100%)" }}>
                    <Typography mb={1} color={"GrayText"}>
                      {t("phone_number")}*
                    </Typography>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue={currentUser?.phone}
                      rules={{ required: t("phone_number_required"), minLength: 14, maxLength: 14 }}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          onChange={(e) => {
                            if (!isNaN(e.target.value)) {
                              field.onChange(e.target.value);
                            }
                          }}
                          error={!!errors?.phone}
                          helperText={errors?.phone?.message}
                          placeholder={t("phone_number")}
                          size="small"
                        />
                      )}
                    />
                      {(errors?.phone?.type == "maxLength" || errors?.phone?.type == "minLength") && <Typography
                          sx={{ marginInlineStart: "12px" }}
                          color={"error"}
                          fontSize={"small"}
                        >
                            {t("phone_14")}
                        </Typography>}
                  </Box>
                  <Box sx={{ width: "min(310px, 100%)" }}>
                    <Typography mb={1} color={"GrayText"}>
                      {t("address")}*
                    </Typography>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue={currentUser?.address}
                      rules={{ required: t("address_required") }}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          error={!!errors?.address}
                          helperText={errors?.address?.message}
                          placeholder={t("address")}
                          size="small"
                        />
                      )}
                    />
                  </Box>
                </Stack>
                
                <Typography
                  mb={2}
                  sx={{ fontSize: "14px" }}
                  color={"error"}
                  ref={errMessage}
                ></Typography>
                {/* <Stack
                  direction={"row"}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <LoadingButton
                    loading={loadingSubmit}
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      borderRadius: "100vh",
                      height: "40px",
                      width: "100px",
                    }}
                  >
                    {t("save")}
                  </LoadingButton>
                </Stack> */}
              </Paper>
            </Stack>
          </form>
        </Container>
      </Box>
    </Box>
  );
};

export default Profile;
