import { VolumeUp } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Fab, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import i18next from "i18next";
import _ from "lodash";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSpeechSynthesis } from "react-speech-kit";
function isValidYoutubeLink(url) {
  const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  return pattern.test(url);
}
const Writing = ({ data, index, total, page, questionsPerPage, setAnswers }) => {
  const { t } = useTranslation();
  const { speak } = useSpeechSynthesis();
  const { control, watch, handleSubmit, setValue } = useFormContext();
  const { examId } = useParams();
  const [loading, setLoading] = useState(false);

  const handleAnswer = (value) => {
    if(!_.isEmpty(value)){
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/exam/user-answer-to-question`, {
          exam_id: examId,
          question_id: data?.questions_id,
          answer: value,
        })
        .then((res) => {
          setValue(`q${(page - 1) * questionsPerPage + index + 1}`, 'answered')
          setAnswers(prev => {
            const arr = prev?.map(e => e?.questions_id == data?.questions?.[0]?.id ? ({questions_id: data?.questions?.[0]?.id, status_answer: true}) : e);
            return arr;
          })
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "20px",
        border: "2px solid #0091F7",
        padding: "20px",
        width: '100%'
        // maxWidth: "540px",
        // minWidth: { xs: "initial", md: "500px" },
      }}
    >
      <Typography mb={2} sx={{ textAlign: "end", fontSize: "small" }}>
      {(page - 1) * questionsPerPage + index + 1} {t("from")} {total}
      </Typography>
      <Stack
        direction={"row"}
        sx={{ alignItems: "center", gap: "20px", marginBottom: "16px", justifyContent: 'space-between' }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            position: "relative",
            "&::after": {
              content: "''",
              width: "100px",
              height: "1px",
              background: "#0091F7",
              position: "absolute",
              left: "0",
              bottom: "-7px",
            },
          }}
        >
          {data?.questions[0]?.title}
    
        </Typography>
       
        {i18next.language != "ar" ? (
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => speak({ text: data?.questions[0]?.title })}
            sx={{ minWidth: "40px", height: "40px", maxWidth: "40px" }}
          >
            <VolumeUp />
          </Fab>
        ) : (
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => {document.querySelector(`#q${(page - 1) * questionsPerPage + index + 1}writing`).click()}}
            sx={{ minWidth: "40px", height: "40px", maxWidth: "40px" }}
          >
            <div style={{display: 'none'}} dangerouslySetInnerHTML={{__html: `
                <input id="q${(page - 1) * questionsPerPage + index + 1}writing" onclick='responsiveVoice.speak("${data?.questions[0]?.title}", "Arabic Male");' type='button' value='► أبدا تهدر' />
            `}}></div>
            <VolumeUp />
          </Fab>
        )}
      </Stack>
      {isValidYoutubeLink(data?.questions[0]?.youtube_link)?<Stack
        direction={"row"}
        sx={{ alignItems: "center", gap: "20px", marginBottom: "16px", justifyContent: 'center' }}
      >
    <iframe 
    width="300" 
    height="250" 
    src={`${data?.questions[0]?.youtube_link}?controls=1`} 
    allowFullScreen><iframe 
    width="300" 
    height="250" 
    src={`${data?.questions[0]?.youtube_link}?controls=1`} 
    allow="fullscreen">
</iframe>
</iframe>
      </Stack> : ""}
      
      <Controller
        name={`writing${data?.questions_id}`}
        control={control}
        defaultValue={data?.current_answer ?? ""}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            fullWidth
            rows={7}
            size="small"
            sx={{
              "& .MuiInputBase-root": {
                borderRadius: "20px",
                background: "#EFEFEF",
                fontSize: "0.9rem",
              },
              "& fieldset": { border: "none" },
            }}
            placeholder={t("textarea_placeholder")}
          />
        )}
      />
      <LoadingButton
        onClick={() => {
          handleAnswer(watch(`writing${data?.questions_id}`));
        }}
        loading={loading}
        variant="contained"
        sx={{ marginTop: "10px" }}
      >
        {t("save")}
      </LoadingButton>
    </Box>
  );
};

export default Writing;
