import { Box, Container, Pagination, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import ImageBox from "./components/ImageBox";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import LoadingComponent from "../../utils/LoadingComponent";

const Album = () => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}/photo-gallery`)
      .then((res) => {
        setImages(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={{ background: "#fff" }}>
      <Container
        maxWidth="lg"
        sx={{ paddingTop: "80px", paddingBottom: "80px" }}
      >
        {loading ? (
          <LoadingComponent />
        ) : (
          <Box>
            <Box
              sx={{
                margin: "auto",
                maxWidth: "400px",
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", marginBottom: "10px" }}
              >
                {t("album")}
              </Typography>
            </Box>
            {images?.length == 0 ? <Typography variant="h5" color={'GrayText'} sx={{textAlign: 'center', margin: '130px auto'}}>{t('no_images')}</Typography> : <Box>
              <Stack
                direction={"row"}
                sx={{
                  flexWrap: "wrap",
                  gap: "5px",
                  margin: "auto",
                  justifyContent: { xs: "center", lg: "initial" },
                }}
              >
                {images?.slice((page-1)*8, page * 8)?.map((e, i) => (
                  <ImageBox key={e?.id} data={e}/>
                ))}
              </Stack>
              <Pagination count={Math.ceil(images.length/8)} page={page} onChange={handleChange} color="primary" sx={{display: 'grid', placeItems: 'center', marginTop: '30px'}}/>
            </Box>}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Album;
