import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSilce";

const NavigationBox = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = ()=>{
    dispatch(logout());
    navigate('/')
    axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`)
  }

  return (
    <Stack
      sx={{
        background: "#1B1B29",
        borderRadius: "20px",
        padding: {xs: "20px 10px", sm: '20px 10px 50px'},
        minWidth: {xs: "min(220px, 100%)", sm: '220px'},
        gap: '7px',
        flexDirection: {xs: 'row', sm: 'column'},
        flexWrap: {xs: 'wrap', sx: 'initial'},
        height: 'fit-content'
      }}
    >
      <NavLink
        to="/exams"
        className={({ isActive }) => isActive ? '' : 'nav-box-hover'}
        style={({ isActive }) => ({
          background: isActive ? "#0091F7" : "#1B1B29",
          padding: "8px",
          borderRadius: "9px",
        })}
      >
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", gap: "10px", color: "#fff" }}
        >
          <img src="/assets/icons/file.svg" width={15} />
          <Typography>{t("exams")}</Typography>
        </Stack>
      </NavLink>
      <NavLink
        to="/completed-exams"
        className={({ isActive }) => isActive ? '' : 'nav-box-hover'}
        style={({ isActive }) => ({
          background: isActive ? "#0091F7" : "#1B1B29",
          padding: "8px",
          borderRadius: "9px",
        })}
      >
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", gap: "10px", color: "#fff" }}
        >
          <img src="/assets/icons/file.svg" width={15} />
          <Typography>{t("completed_exams")}</Typography>
        </Stack>
      </NavLink>
      <NavLink
        to="/profile"
        className={({ isActive }) => isActive ? '' : 'nav-box-hover'}
        style={({ isActive }) => ({
          background: isActive ? "#0091F7" : "#1B1B29",
          padding: "8px",
          borderRadius: "9px",
        })}
      >
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", gap: "10px", color: "#fff" }}
        >
          <img src="/assets/icons/person.svg" width={15} />
          <Typography>{t("profile")}</Typography>
        </Stack>
      </NavLink>
      <NavLink
        to="/change-password"
        className={({ isActive }) => isActive ? '' : 'nav-box-hover'}
        style={({ isActive }) => ({
          background: isActive ? "#0091F7" : "#1B1B29",
          padding: "8px",
          borderRadius: "9px",
        })}
      >
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", gap: "10px", color: "#fff" }}
        >
          <img src="/assets/icons/shield.svg" width={15} />
          <Typography>{t("change_password")}</Typography>
        </Stack>
      </NavLink>
      <Stack
        direction={"row"}
        className={'nav-box-hover'}
        sx={{
          alignItems: "center",
          gap: "10px",
          color: "#fff",
          padding: "8px",
          cursor: "pointer",
          borderRadius: "9px",
        }}
        onClick={handleClickOpen}
      >
        <img src="/assets/icons/logout.svg" width={15} />
        <Typography>{t("logout")}</Typography>
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{'& .MuiPaper-root': {width: 'min(300px, 100%)'}}}
      >
        <DialogTitle id="alert-dialog-title">
          {t('logout')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('want_logout')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="gray" variant="outlined">{t('no')}</Button>
          <Button onClick={handleLogout} autoFocus variant="contained">
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default NavigationBox;
