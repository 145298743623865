import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link, NavLink } from "react-router-dom";
import LanguageButton from "./LanguageButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

const Footer = () => {
  const { t } = useTranslation();
  const currentUser = useSelector((s) => s.user);
  const [data, setData] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/connect_us`)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Box sx={{ background: "#03AED2" }}>
      <Container maxWidth="lg" sx={{ paddingTop: "30px" }}>
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexWrap: "wrap",
            rowGap: "25px",
            paddingBottom: "30px",
          }}
        >
          <Stack sx={{ gap: "10px" }}>
            <Stack style={{ display:"none" }}>
              <img src="/assets/icons/logo.png" width={150} />
              {/* <Typography fontSize={'0.8rem'} sx={{whiteSpace: 'nowrap', color: '#fff', paddingInlineStart: '30px', transform: 'translateY(-0.8rem)'}}>فرع الزرقاء الثاني</Typography> */}
            </Stack>
            <Typography
              sx={{ color: "#fff", maxWidth: "265px", fontSize: "0.8rem" }}
            >
              {t("project_desc")}
            </Typography>
            <Stack direction={"row"} sx={{ gap: "10px", alignItems: 'center' }}>
              {data?.social_medias?.map(e => (
                <a key={e?.id} href={e?.link} target="_blank"><img src={e?.image} width={25} /></a>
              ))}
            </Stack>
            <LanguageButton />
          </Stack>
          <Stack sx={{ gap: "10px" }}>
            <Typography
              sx={{ color: "#fff", fontWeight: "600", marginBottom: "7px" }}
            >
              {t("association")}
            </Typography>
            <NavLink
              to="/"
              style={({ isActive }) => ({
                color: isActive ? "##fff" : "#fff",
                fontSize: "0.9rem",
              })}
            >
              {i18next.language == "ar" ? (
                <ArrowBackIosNewIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              ) : (
                <ArrowForwardIosIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              )}{" "}
              {t("home")}
            </NavLink>
            <NavLink
              to="/about-us"
              style={({ isActive }) => ({
                color: isActive ? "##fff" : "#fff",
                fontSize: "0.9rem",
              })}
            >
              {i18next.language == "ar" ? (
                <ArrowBackIosNewIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              ) : (
                <ArrowForwardIosIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              )}{" "}
              {t("about_us")}
            </NavLink>
            <NavLink
              to="/album"
              style={({ isActive }) => ({
                color: isActive ? "##fff" : "#fff",
                fontSize: "0.9rem",
              })}
            >
              {i18next.language == "ar" ? (
                <ArrowBackIosNewIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              ) : (
                <ArrowForwardIosIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              )}{" "}
              {t("album")}
            </NavLink>
            <NavLink
              to="/news"
              style={({ isActive }) => ({
                color: isActive ? "##fff" : "#fff",
                fontSize: "0.9rem",
              })}
            >
              {i18next.language == "ar" ? (
                <ArrowBackIosNewIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              ) : (
                <ArrowForwardIosIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              )}{" "}
              {t("news")}
            </NavLink>
          </Stack>
          <Stack sx={{ gap: "10px" }}>
            <Typography
              sx={{ color: "#fff", fontWeight: "600", marginBottom: "7px" }}
            >
              {t("useful_links")}
            </Typography>
            {!currentUser?.token && (
              <>
                <NavLink
                  to="/sign-in"
                  style={({ isActive }) => ({
                    color: isActive ? "##fff" : "#fff",
                    fontSize: "0.9rem",
                  })}
                >
                  {i18next.language == "ar" ? (
                    <ArrowBackIosNewIcon
                      sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                    />
                  ) : (
                    <ArrowForwardIosIcon
                      sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                    />
                  )}{" "}
                  {t("sign_in")}
                </NavLink>
                <NavLink
                  to="/sign-up"
                  style={({ isActive }) => ({
                    color: isActive ? "##fff" : "#fff",
                    fontSize: "0.9rem",
                  })}
                >
                  {i18next.language == "ar" ? (
                    <ArrowBackIosNewIcon
                      sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                    />
                  ) : (
                    <ArrowForwardIosIcon
                      sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                    />
                  )}{" "}
                  {t("sign_up")}
                </NavLink>
              </>
            )}
            {currentUser?.token && (
              <NavLink
                to="/profile"
                style={({ isActive }) => ({
                  color: isActive ? "##fff" : "#fff",
                  fontSize: "0.9rem",
                })}
              >
                {i18next.language == "ar" ? (
                  <ArrowBackIosNewIcon
                    sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                  />
                ) : (
                  <ArrowForwardIosIcon
                    sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                  />
                )}{" "}
                {t("profile")}
              </NavLink>
            )}
            <NavLink
              to="/exams"
              style={({ isActive }) => ({
                color: isActive ? "##fff" : "#fff",
                fontSize: "0.9rem",
              })}
            >
              {i18next.language == "ar" ? (
                <ArrowBackIosNewIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              ) : (
                <ArrowForwardIosIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              )}{" "}
              {t("exams")}
            </NavLink>
            <NavLink
              to="/contact-us"
              style={({ isActive }) => ({
                color: isActive ? "##fff" : "#fff",
                fontSize: "0.9rem",
              })}
            >
              {i18next.language == "ar" ? (
                <ArrowBackIosNewIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              ) : (
                <ArrowForwardIosIcon
                  sx={{ fontSize: "0.8rem", transform: "translateY(1px)" }}
                />
              )}{" "}
              {t("contact_us")}
            </NavLink>
          </Stack>
          <Stack sx={{ gap: "10px" }}>
            <Typography
              sx={{ color: "#fff", fontWeight: "600", marginBottom: "7px" }}
            >
              {t("contact_us")}
            </Typography>
            <Stack direction={"row"} sx={{ alignItems: "flex-start" }}>
              <img
                src="/assets/icons/location.svg"
                width={15}
                style={{ marginInlineEnd: "6px", transform: "translateY(2px)" }}
              />
              <Box>
                <Typography sx={{ color: "#fff", fontSize: "0.8rem" }}>
                  {t("address")}
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "0.8rem",
                    maxWidth: "180px",
                  }}
                >
الإمارات العربية المتحدة - أبو ظبي                </Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} sx={{ alignItems: "flex-start" }}>
              <img
                src="/assets/icons/phone.svg"
                width={15}
                style={{ marginInlineEnd: "6px", transform: "translateY(5px)" }}
              />
              <Box>
                <Typography sx={{ color: "#fff", fontSize: "0.8rem" }}>
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "0.8rem",
                    maxWidth: "180px",
                  }}
                >
                  {data?.connect_us?.phone}
                </Typography>
              </Box>
            </Stack>
            <Stack direction={"row"} sx={{ alignItems: "flex-start" }}>
              <img
                src="/assets/icons/mail.svg"
                width={15}
                style={{ marginInlineEnd: "6px", transform: "translateY(5px)" }}
              />
              <Box>
                <Typography sx={{ color: "#fff", fontSize: "0.8rem" }}>
                  {t("email")}
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "0.8rem",
                    maxWidth: "180px",
                  }}
                >
                  {data?.connect_us?.email}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ borderColor: "#4f4f4f" }} />
        <Stack
          direction={"row"}
          sx={{ justifyContent: "space-between", padding: "25px" }}
        >
          <Typography sx={{ fontSize: "0.8rem", color: "#fff" }}>
          جميع الحقوق محفوظة للمعلمة حنان محمود بوادي 
          </Typography>
          <Typography
            sx={{ fontSize: "0.8rem", color: "#fff" }}
            component={Link}
            to="/privacy-usage-policy"
          >
            {t("privacy_usage_policy")}
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;
