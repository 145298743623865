import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import LoadingComponent from "../../utils/LoadingComponent";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/userSilce";

const DialogFinish = ({ open, handleClose, exam, answers }) => {
  const { t } = useTranslation();
  const [loadingFinish, setLoadingFinish] = useState(false)
  const { examId } = useParams(); 
  const navigate = useNavigate()
  const currentUser = useSelector(s=>s.user).currentUser
  const dispatch = useDispatch();

  const handleFinish = ()=>{
    setLoadingFinish(true)
    axios.post(`${process.env.REACT_APP_API_URL}/exam/submit-exam`, {exam_id: examId})
    .then(res => {
      navigate(`/exams/${examId}`);
      axios.get(`${process.env.REACT_APP_API_URL}/profile`)
      .then(response => {
        dispatch(login({
          token: currentUser?.token,
          user: { ...response?.data?.data, token: currentUser?.token },
        }))
      }).catch(error => {
        console.log(error)
      })
    }).catch(err => {
      console.log(err)
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{exam?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack sx={{ width: { xs: "100%", sm: "400px" }, '& > div:nth-child(odd)': {background: '#eee'} }}>
            {answers?.map((e,i) => (
              <Stack key={i} direction={'row'} sx={{justifyContent: 'space-between', gap: '70px', padding: '5px'}}>
                <Typography>{t('the_question')} {i+1}</Typography>
                {e?.status_answer ? <Typography color={'success.main'}>{t('answered')}</Typography> : <Typography color={'error.main'}>{t('not_answered')}</Typography>}
              </Stack>
            ))}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="gray" variant="outlined">{t('cancel')}</Button>
        <LoadingButton loading={loadingFinish} variant="contained" onClick={handleFinish} autoFocus>
          {t('submit_exam')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFinish;
