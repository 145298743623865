import {
  Box,
  Container,
  Stack,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from "@mui/material";
import React, { useEffect, useState,useRef } from "react";
import PageHeader from "./components/PageHeader";
import NavigationBox from "../utils/NavigationBox";
import { useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "../utils/LoadingComponent";
import axios from "axios";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function addMinutes(date, minutes) {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}

const ReviewExamDetails = () => {
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState({});
  const [data, setData] = useState([]);
  const { examId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isBreak, setIsBreak] = useState(false);
  const currentUser = useSelector((s) => s.user).currentUser;
  const [error, setError] = useState("");
const totalRef=useRef(0);

  const getExam=()=>{
    axios.post(
      'https://api.step-ar.site/api/v1/user/exam/get-exam-questions?page=1',
        {
          'exam_id': examId,
          'paginate': totalRef.current,
        },
        {
          headers: {
            'Authorization': `Bearer ${currentUser?.token}`,
            'Accept': 'application/json'
          }
        }
      )
      .then((res) => {
        const data = res?.data?.data?.data;
        console.log(data);
        totalRef.current=res.data.data.total;
       if(totalRef.current>0){
         setData(data);
       }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching exam questions:", err);
        setLoading(false); 
      });
  } 
   
useEffect(() => {
  getExam()
  getExam()

}, [examId, t]);

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return (
      <Typography variant="h5" color={"GrayText"}>
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ backgroundColor: "#fff", marginTop: "30px" }}>
      {/* Your UI rendering logic for exam details */}
      <Container maxWidth="lg" sx={{ backgroundColor: "#fff", paddingTop: "80px" }}>
        <Stack direction="column" spacing={2}>
          {/* Example of mapping over questions */}
          {data.length&&data.map((item) => (
            <Box key={item.id} >
            <Typography variant="h6">السؤال:</Typography> 
              <Typography variant="h6">{item.questions[0].title}</Typography>
              <FormControl component="fieldset">
              <Typography variant="h6">الإجابة:</Typography> 
                <RadioGroup value={item.current_answer}>
                {item.questions[0].options.length ? (
                  item.questions[0].options.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      value={option.title}
                      control={<Radio disabled={!option.is_correct} />} // Disable incorrect options
                      label={option.title}
                      style={{color:"green"}}
                    />
                  ))
                ) : (
                  <>
                        <Typography variant="h6">الإجابة:</Typography> 
                        <Typography variant="h6"> {item.current_answer}</Typography>
                  </>
          )} 
          {/* <Typography variant="h6"> {item.questions[0].score}</Typography> */}


                </RadioGroup> 
              </FormControl>
            </Box>
          ))}
        </Stack>
      </Container>
    </Box>
  );
};

export default ReviewExamDetails;
