import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import NewsCard from "./components/NewsCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next, { t } from "i18next";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import LoadingComponent from "../../utils/LoadingComponent";

const News = () => {
  const [loading, setLoading ] = useState(true)
  const [news, setNews] = useState([])
  const xs = useMediaQuery(theme => theme.breakpoints.up('xs'))
  const sm = useMediaQuery(theme => theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme => theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme => theme.breakpoints.up('lg'))

  useEffect(()=>{
    window.scrollTo(0, 0);
    axios.get(`${process.env.REACT_APP_API_URL}/news`)
    .then(res => {
      setNews(res?.data?.data)
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  },[])

  return (
    <Box sx={{ background: "#fff" }}>
      <Container
        maxWidth="lg"
        sx={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        {loading ? <Box sx={{margin: '100px 0'}}><LoadingComponent/></Box> : <Box>
          <Box
            sx={{
              margin: "auto",
              maxWidth: "400px",
              textAlign: "center",
              marginBottom: '-15px'
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              {t('news')}
            </Typography>
          </Box>
          {news?.length == 0 ? <Typography variant="h5" color={'GrayText'} sx={{textAlign: 'center', margin: '130px auto'}}>لا يوجد إبداعات كتابية</Typography> : <Splide
            aria-label="My Favorite Images"
            options={{ height: "27rem", arrows: false, perPage: lg ? 3 : md ? 2 : xs ? 1 : 1, ... i18next.language == 'ar' && {direction: 'rtl', paginationDirection: 'rtl'} }}
          >
            {news?.map((e,i) => (
              <SplideSlide
                key={e?.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <NewsCard data={e}/>
              </SplideSlide>
            ))}
          </Splide>}
        </Box>}
      </Container>
    </Box>
  );
};

export default News;
