import { Box, Dialog, Typography } from "@mui/material";
import React from "react";

const ImageBox = ({data}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box className="album-box">
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <img src={data?.image} />
      </Dialog>
      <img
        src={data?.image}
        style={{ objectFit: "cover", width: "284px", height: "200px" }}
      />
      <Box className="album-box-overlay" onClick={handleClickOpen}>
        <Box
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "20px",
            color: "#fff",
            "&::before": {
              content: "''",
              height: "100%",
              width: "2px",
              background: "#0091F7",
              position: "absolute",
              top: "0",
              left: "-10px",
              borderRadius: "100vh",
            },
          }}
        >
          <Typography fontWeight={'bold'}>{data?.title}</Typography>
          <Typography sx={{color: '#ddd'}}>{data?.description}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageBox;
