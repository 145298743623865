import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { memo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/userSilce";

const ExamDetailsBox = ({data}) => {
  const { t } = useTranslation();
  const { examId } = useParams(); 
  const navigate = useNavigate()
  const currentDate = useRef(Date.now());
  const currentUser = useSelector(s=>s.user).currentUser
  const dispatch = useDispatch();

  const renderer = useCallback(({ hours, minutes, seconds, completed }) => {
    if (completed) {
      axios.post(`${process.env.REACT_APP_API_URL}/exam/submit-exam`, {exam_id: examId})
      .then(res => {
        navigate(`/exams/${examId}`);
        Swal.fire({
          // title: t('success'),
          html: t("time_up"),
          confirmButtonColor: 'hsl(28 89% 63% / 1)',
          confirmButtonText: t('ok'),
          icon: 'warning'
        })
        axios.get(`${process.env.REACT_APP_API_URL}/profile`)
        .then(response => {
          dispatch(login({
            token: currentUser?.token,
            user: { ...response?.data?.data, token: currentUser?.token },
          }))
        }).catch(error => {
          console.log(error)
        })
      }).catch(err => {
        console.log(err)
      })
      // Render a completed state
      return <span>{t('time_up')}</span>;
    } else {
      // Render a countdown
      return (
        <span>
          {hours}:{minutes}:{seconds} {t('minute')}
        </span>
      );
    }
  }, []);

  const CountdownWrapper = ()=><Countdown date={currentDate.current + data?.data?.[0]?.remaining_time_in_milliseconds} renderer={renderer} />
  const MemoCountdown = memo(CountdownWrapper)

  return (
    <Stack
      sx={{
        background: "#1B1B29",
        borderRadius: "20px",
        padding: { xs: "20px 10px", sm: "20px 10px" },
        width: { xs: "min(250px, 100%)", sm: "250px" },
        gap: "7px",
        flexDirection: { xs: "row", sm: "column" },
        flexWrap: { xs: "wrap", sx: "initial" },
        height: "fit-content",
        margin: 'auto'
      }}
    >
      <Stack
        sx={{
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          gap: "6px",
        }}
      >
        <img src="/assets/icons/clock.svg" width={20} />
        <Typography
          sx={{ fontWeight: "700", color: "#fff", fontSize: "1rem" }}
        >
          {t("time_left")}
        </Typography>
        <Typography
          color="primary"
          sx={{ fontWeight: "700", fontSize: "1rem" }}
        >
          <MemoCountdown/>
        </Typography>
      </Stack>
      <Divider sx={{ borderColor: "hsl(240 21% 39% / 1)" }} />
      <Stack
        sx={{
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
          gap: "6px",
        }}
      >
        <img src="/assets/icons/file-green.svg" width={16} />
        <Typography
          sx={{ fontWeight: "700", color: "#fff", fontSize: "1rem" }}
        >
          {t("questions_number")}
        </Typography>
        <Typography
          color="primary"
          sx={{ fontWeight: "700", fontSize: "1rem" }}
        >
          {data?.total} {t('question')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ExamDetailsBox;
