import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth='lg'>
      <Box sx={{ color: "#fff", padding: '35px 0' }}>
        <Typography mb={2} variant="h4" sx={{ fontWeight: "700" }}>
          {t('profile')}
        </Typography>
        <Breadcrumbs aria-label="breadcrumb" style={{ color: "#aaa" }}>
          <Link className="underline-hover" to="/">
            {t('home')}
          </Link>
          <Typography sx={{ color: "#fff" }}>{t('profile')}</Typography>
        </Breadcrumbs>
      </Box>
    </Container>
  );
};

export default PageHeader;
