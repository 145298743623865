import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useRef, useState } from "react";
import PageHeader from "./components/PageHeader";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSilce";
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from "@mui/icons-material";

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: "100vh",
    padding: "10px",
    background: "#EFEFEF",
  },
  "& input": { height: "7px" },
  "& fieldset": { border: "none" },
});

const SignIn = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const errMessage = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
      .then((res) => {
        dispatch(
          login({
            token: res.data.data.token,
            user: { ...res.data.data },
          })
        );
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.data.token}`;
        window.location.href = `${window.location.origin}/exams`
        // navigate("/exams");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        errMessage.current.textContent =
          err?.response?.data?.message ?? t('smth_went_wrong');
      });
  };

  return (
    <Box>
      <PageHeader />
      <Box sx={{ background: "#fff" }}>
        <Container maxWidth="lg">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "50px 0",
              }}
            >
              <Box>
                <Typography
                  mb={2}
                  variant="h4"
                  sx={{ fontWeight: "600", textAlign: "center" }}
                >
                  {t("title_sign_in")}
                </Typography>
                <Paper
                  elevation={2}
                  sx={{
                    borderRadius: "20px",
                    padding: "25px 25px 35px",
                    width: { xs: "100%", sm: "390px" },
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: "600" }}>
                    {t("sign_in")}
                  </Typography>
                  <Typography color={"GrayText"} mb={3}>
                    {t("if_have_account")}
                  </Typography>
                  <Box mb={3}>
                    <Typography mb={1} color={"GrayText"}>
                      {t("username")}*
                    </Typography>
                    <Controller
                      name="username"
                      defaultValue={""}
                      control={control}
                      rules={{ required: t("username_required") }}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          error={!!errors?.username}
                          helperText={errors?.username?.message}
                          placeholder={t("username")}
                          size="small"
                        />
                      )}
                    />
                  </Box>
                  <Box mb={3}>
                    <Typography mb={1} color={"GrayText"}>
                      {t("password")}*
                    </Typography>
                    <Controller
                      name="password"
                      defaultValue={""}
                      control={control}
                      rules={{ required: t("password_required") }}
                      render={({ field }) => (
                        <StyledTextField
                          {...field}
                          error={!!errors?.password}
                          helperText={errors?.password?.message}
                          placeholder={t("password")}
                          size="small"
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowPassword((prev) => !prev);
                                  }}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Typography
                    mb={2}
                    sx={{ fontSize: "14px" }}
                    color={"error"}
                    ref={errMessage}
                  ></Typography>
                  <Stack
                    direction={"row"}
                    sx={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={{
                        borderRadius: "100vh",
                        height: "40px",
                        width: "100px",
                      }}
                    >
                      {t("login")}
                    </LoadingButton>
                    <Typography color={"GrayText"} fontSize={"0.8rem"}>
                      {t("dont_have_account")}{" "}
                      <Link to={"/sign-up"} style={{ color: "#0091F7" }}>
                        {t("sign_up")}
                      </Link>
                    </Typography>
                  </Stack>
                </Paper>
              </Box>
            </Box>
          </form>
        </Container>
      </Box>
    </Box>
  );
};

export default SignIn;
