import { Box, Container, Typography } from "@mui/material";
import axios from "axios";
import i18next, { t } from "i18next";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import LoadingComponent from "../../utils/LoadingComponent";

const PrivacyPolicy = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_API_URL}/privacy_policy`)
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <Box sx={{ background: "#fff" }}>
      <Container
        maxWidth="lg"
        sx={{ paddingTop: "100px", paddingBottom: "100px" }}
      >
        {loading ? (
          <Box sx={{ padding: "100px 0" }}>
            <LoadingComponent />
          </Box>
        ) : (
          <Box sx={{'& a': {color: theme=>theme.palette.primary.main, textDecoration: 'underline'}}}>
            {/* <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("privacy_usage_policy")}
            </Typography> */}
            <p dangerouslySetInnerHTML={{__html: i18next.language == 'ar' ? data?.description_ar : data?.description_en /*?.split('\n')?.join('</br>')*/}}></p>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
