import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

function Navigation(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.user);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> */}
      <Box my={2}>
        <Link to='/'>
          <Box sx={{display: 'grid', placeItems: 'center'}}>
            <img  src="/assets/icons/logo.png" style={{width: '4rem'}} />
            {/* <Typography fontSize={'0.7rem'} sx={{whiteSpace: 'nowrap'}}>فرع الزرقاء الثاني</Typography> */}
          </Box>
        </Link>
      </Box>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            LinkComponent={NavLink}
            to={"/"}
            style={({ isActive }) => ({ color: isActive ? "#0391F6" : "#000" })}
          >
            <ListItemText primary={t("home")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            LinkComponent={NavLink}
            to={"/about-us"}
            style={({ isActive }) => ({ color: isActive ? "#0391F6" : "#000" })}
          >
            <ListItemText primary={t("about_us")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            LinkComponent={NavLink}
            to={"/news"}
            style={({ isActive }) => ({ color: isActive ? "#0391F6" : "#000" })}
          >
            <ListItemText primary={t("news")} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            LinkComponent={NavLink}
            to={"/album"}
            style={({ isActive }) => ({ color: isActive ? "#0391F6" : "#000" })}
          >
            <ListItemText primary={t("album")} />
          </ListItemButton>
        </ListItem>
        {currentUser?.token && <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            LinkComponent={NavLink}
            to={"/exams"}
            style={({ isActive }) => ({ color: isActive ? "#0391F6" : "#000" })}
          >
            <ListItemText primary={t("exams")} />
          </ListItemButton>
        </ListItem>}
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            LinkComponent={NavLink}
            to={"/contact-us"}
            style={({ isActive }) => ({ color: isActive ? "#0391F6" : "#000" })}
          >
            <ListItemText primary={t("contact_us")} />
          </ListItemButton>
        </ListItem>
        {!currentUser?.token && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ textAlign: "center" }}
                LinkComponent={NavLink}
                to={"/sign-in"}
                style={({ isActive }) => ({
                  color: isActive ? "#0391F6" : "#000",
                })}
              >
                <ListItemText primary={t("sign_in")} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ textAlign: "center" }}
                LinkComponent={NavLink}
                to={"/sign-up"}
                style={({ isActive }) => ({
                  color: isActive ? "#0391F6" : "#000",
                })}
              >
                <ListItemText primary={t("sign_up")} />
              </ListItemButton>
            </ListItem>
          </>
        )}
        {currentUser?.token && (
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              LinkComponent={NavLink}
              to={"/profile"}
              style={({ isActive }) => ({
                color: isActive ? "#0391F6" : "#000",
              })}
            >
              <ListItemText primary={t("profile")} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" , padding:' 0px 150px', position: 'fixed', width: '100%', zIndex: 1000 , backgroundColor: '#03AED2' , boxShadow: '0 0 10px rgba(0,0,0,0.1)' , top: 0 , left: 0}} >
     
      <CssBaseline />
      <AppBar
        component="nav"
        position="static"
        sx={{ background: "transparent", boxShadow: "none", marginTop: '5px' }}
      >
        <Toolbar
          sx={{
            justifyContent: { xs: "space-between", md: "initial" },
            padding: { xs: "15px 0 0", md: "0" },
          }}
        >
          <IconButton
            color="white"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            MUI
          </Typography> */}
          <Box sx={{display: 'grid', placeItems: 'center'}} >
            <Link to='/'>
              <Box sx={{display: 'grid', placeItems: 'center'}}>
                <img  src="/assets/icons/logo.png" style={{width: '4rem'}} />
                {/* <Typography fontSize={'0.7rem'} sx={{whiteSpace: 'nowrap'}}>فرع الزرقاء الثاني</Typography> */}
              </Box>
            </Link>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "space-around",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Stack
              direction={"row"}
              sx={{
                justifyContent: "space-around",
                width: "100%",
                padding: { md: "0 5px", lg: "0 50px" },
                "& *": { whiteSpace: "nowrap" },
                gap: "13px",
              }}
            >
              <NavLink
                to="/"
                style={({ isActive }) => ({
                  color: isActive ? "#0391F6" : "#fff",
                })}
              >
                {t("home")}
              </NavLink>
              <NavLink
                to="/about-us"
                style={({ isActive }) => ({
                  color: isActive ? "#0391F6" : "#fff",
                })}
              >
                {t("about_us")}
              </NavLink>
              <NavLink
                to="/album"
                style={({ isActive }) => ({
                  color: isActive ? "#0391F6" : "#fff",
                })}
              >
                {t("album")}
              </NavLink>
              <NavLink
                to="/news"
                style={({ isActive }) => ({
                  color: isActive ? "#0391F6" : "#fff",
                })}
              >
                {t("news")}
              </NavLink>
              {currentUser?.token && <NavLink
                to="/exams"
                style={({ isActive }) => ({
                  color: isActive ? "#0391F6" : "#fff",
                })}
              >
                {t("exams")}
              </NavLink>}
              <NavLink
                to="/contact-us"
                style={({ isActive }) => ({
                  color: isActive ? "#0391F6" : "#fff",
                })}
              >
                {t("contact_us")}
              </NavLink>
            </Stack>
            {!currentUser?.token && (
              <Stack direction={"row"} sx={{ gap: "6px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: "100vh",
                    whiteSpace: "nowrap",
                    color: "#fff",
                    width: "145px",
                  }}
                  LinkComponent={Link}
                  to="/sign-in"
                >
                  {t("sign_in")}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{
                    borderRadius: "100vh",
                    whiteSpace: "nowrap",
                    color: "#fff",
                    width: "145px",
                  }}
                  LinkComponent={Link}
                  to="/sign-up"
                >
                  {t("sign_up")}
                </Button>
              </Stack>
            )}
            {currentUser?.token && (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "100vh",
                  whiteSpace: "nowrap",
                  color: "#fff",
                  width: "145px",
                }}
                LinkComponent={Link}
                to="/profile"
              >
                {t("profile")}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {/* <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Outlet/>
      </Box> */}
    </Box>
  );
}

Navigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navigation;
