import React from "react";
import Header from "./components/Header";
import { Box, Container, Divider } from "@mui/material";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { Outlet, useLocation } from "react-router-dom";

const Layout = () => {
  const pathname = useLocation().pathname;
  return (
    <Box>
      <Box
        sx={{
          backgroundImage: "url(/assets/images/background.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          boxShadow: "inset 0 0 0 999999999px rgb(14 56 87 / 90%);",
        }}
      >
        {!(pathname.includes("exams") && pathname.includes("questions")) && (
          <>
            <Container maxWidth="lg">
              {/* <Header /> */}
              <Navigation />
            </Container>
            <Divider
              sx={{ borderColor: "rgb(14 56 87 / 60%)", marginTop: "10px" }}
            />
          </>
        )}
        <Outlet />
      </Box>
      {!(pathname.includes("exams") && pathname.includes("questions")) && (
        <Footer />
      )}
    </Box>
  );
};

export default Layout;
