import {
  Avatar,
  Box,
  Checkbox,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PageHeader from "./components/PageHeader";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingComponent from "../utils/LoadingComponent";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { login } from "../../redux/userSilce";
import { LoadingButton } from "@mui/lab";
import {
  AddPhotoAlternate,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputBase-root": {
    borderRadius: "100vh",
    padding: "10px",
    background: "#EFEFEF",
  },
  "& input": { height: "7px" },
  "& fieldset": { border: "none" },
});

const StyledFormControl = styled(FormControl)({
  "& .MuiInputBase-root": {
    borderRadius: "100vh",
    padding: "2px",
    background: "#EFEFEF",
  },
  "& fieldset": { border: "none" },
});

const SignUp = () => {
  const { t } = useTranslation();
  const [qualifications, setQualifications] = useState([]);
  const [branches, setBranches] = useState([]);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const errMessage = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const inputImage = useRef();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onChange" });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    let count = 0;

    axios
      .get(`${process.env.REACT_APP_API_URL}/qualification`)
      .then((res) => {
        setQualifications(res?.data?.data);
        count++;
        if (count == 2) {
          setLoading(false);
        }
      })
      .catch((err) => {
        count++;
        if (count == 2) {
          setLoading(false);
        }
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/branch`)
      .then((res) => {
        setBranches(res?.data?.data);
        count++;
        if (count == 2) {
          setLoading(false);
        }
      })
      .catch((err) => {
        count++;
        if (count == 2) {
          setLoading(false);
        }
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/school`)
      .then((res) => {
        setSchools(res?.data?.data);
        count++;
        if (count == 2) {
          setLoading(false);
        }
      })
      .catch((err) => {
        count++;
        if (count == 2) {
          setLoading(false);
        }
      });
  }, []);

  const onSubmit = (data) => {
    setLoadingSubmit(true);
    const formData = new FormData();
    Object.keys(data)?.forEach((key) => {
      if (key != "branches_ids") {
        formData.append(key, data[key]);
      }
    });
    data?.branches_ids?.forEach((e, i) => {
      formData.append(`branches_ids[${i}]`, e);
    });
    image && formData.append("image", image);
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/register`, formData)
      .then((res) => {
        dispatch(
          login({
            token: res.data.data.token,
            user: { ...res.data.data },
          })
        );
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.data.token}`;
        window.location.href = `${window.location.origin}/exams`;
        // navigate("/exams");
      })
      .catch((err) => {
        console.log(err);
        errMessage.current.textContent =
          err?.response?.data?.message ?? t("smth_went_wrong");
        setLoadingSubmit(false);
      });
  };

  return (
    <Box>
      <PageHeader />
      <Box sx={{ background: "#fff" }}>
        <Container maxWidth="lg">
          {loading ? (
            <LoadingComponent />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "50px 0",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  {/* <Typography
                    mb={2}
                    variant="h4"
                    sx={{ fontWeight: "600", textAlign: "center" }}
                  >
                    {t("title_sign_up")}
                  </Typography> */}
                  <Paper
                    elevation={2}
                    sx={{
                      borderRadius: "20px",
                      padding: "25px 25px 35px",
                      width: "min(700px, 100%)",
                      margin: "auto",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "600" }}>
                      {t("sign_up")}
                    </Typography>
                    <Typography color={"GrayText"} mb={3}>
                      {t("if_have_account")}
                    </Typography>
                    <Stack
                      direction={"row"}
                      sx={{
                        alignItems: "flex-end",
                        marginBottom: "30px",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: "150px",
                          height: "150px",
                          boxShadow: "-5px 5px 5px rgb(0, 0, 0, 0.1)",
                        }}
                        src={image && URL.createObjectURL(image)}
                        alt="profile picture"
                      />
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={inputImage}
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          setValue("image", e.target.files[0]);
                        }}
                      />
                      <IconButton
                        color="primary"
                        onClick={() => {
                          inputImage.current.click();
                        }}
                        sx={{ transform: "translateX(-10px)" }}
                      >
                        <AddPhotoAlternate fontSize="large" />
                      </IconButton>
                    </Stack>
                    <Stack
                      mb={3}
                      direction={"row"}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ width: "min(310px, 100%)" }}>
                        <Typography mb={1} color={"GrayText"}>
                          {t("first_name")}*
                        </Typography>
                        <Controller
                          name="first_name"
                          control={control}
                          defaultValue={""}
                          rules={{ required: t("first_name_required") }}
                          render={({ field }) => (
                            <StyledTextField
                              {...field}
                              error={!!errors?.first_name}
                              helperText={errors?.first_name?.message}
                              placeholder={t("first_name")}
                              size="small"
                            />
                          )}
                        />
                      </Box>
                      <Box sx={{ width: "min(310px, 100%)" }}>
                        <Typography mb={1} color={"GrayText"}>
                          {t("last_name")}*
                        </Typography>
                        <Controller
                          name="last_name"
                          control={control}
                          defaultValue={""}
                          rules={{ required: t("last_name_required") }}
                          render={({ field }) => (
                            <StyledTextField
                              {...field}
                              error={!!errors?.last_name}
                              helperText={errors?.last_name?.message}
                              placeholder={t("last_name")}
                              size="small"
                            />
                          )}
                        />
                      </Box>
                    </Stack>
                    <Stack
                      mb={3}
                      direction={"row"}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ width: "min(310px, 100%)" }}>
                        <Typography mb={1} color={"GrayText"}>
                          {t("username")}*
                        </Typography>
                        <Controller
                          name="username"
                          control={control}
                          defaultValue={""}
                          rules={{ required: t("username_required") }}
                          render={({ field }) => (
                            <StyledTextField
                              {...field}
                              error={!!errors?.username}
                              helperText={errors?.username?.message}
                              placeholder={t("username")}
                              size="small"
                            />
                          )}
                        />
                      </Box>
                      
                    </Stack>
                    <Stack
                    mb={3}
                    direction={"row"}
                    sx={{
                      width: "100%",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}>
                  
                      <Box mb={3} sx={{ width: "min(310px, 100%)" }}>
                        <Stack
                          mb={1}
                          direction={"row"}
                          sx={{ alignItems: "flex-end", gap: "5px" }}
                        >
                          <Typography color={"GrayText"}>
                            {t("schools")}*
                          </Typography>
                        </Stack>
                        <Controller
                          name="school_id"
                          control={control}
                          defaultValue={[]}
                          rules={{ required: t("school_required") }}
                          render={({ field }) => (
                            <StyledFormControl fullWidth size="small">
                              {/* <InputLabel id="demo-simple-select-label">{t('choose_qualification')}</InputLabel> */}
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => {
                                  field.onChange(
                                    typeof e?.target?.value === "string"
                                      ? e?.target?.value.split(",")
                                      : e?.target?.value
                                  );
                                }}
                                
                                // value={age}
                                // label={t('choose_qualification')}
                                // onChange={handleChange}
                              >
                                {schools?.map((q) => (
                                  <MenuItem key={q?.id} value={q?.id}>
                                    {q?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Typography
                                sx={{ marginInlineStart: "12px" }}
                                color={"error"}
                                fontSize={"small"}
                              >
                                {errors?.school_id?.message}
                              </Typography>
                            </StyledFormControl>
                          )}
                        />
                      </Box>
                      <Box mb={3} sx={{ width: "min(310px, 100%)" }}>
                        <Stack
                          mb={1}
                          direction={"row"}
                          sx={{ alignItems: "flex-end", gap: "5px" }}
                        >
                          <Typography color={"GrayText"}>
                            {t("branches")}*
                          </Typography>
                        </Stack>
                        <Controller
                          name="branches_ids"
                          control={control}
                          defaultValue={[]}
                          rules={{ required: t("branches_required") }}
                          render={({ field }) => (
                            <StyledFormControl fullWidth size="small">
                              {/* <InputLabel id="demo-simple-select-label">{t('choose_qualification')}</InputLabel> */}
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => {
                                  field.onChange(
                                    typeof e?.target?.value === "string"
                                      ? e?.target?.value.split(",")
                                      : e?.target?.value
                                  );
                                }}
                                multiple
                                // value={age}
                                // label={t('choose_qualification')}
                                // onChange={handleChange}
                              >
                                {branches?.map((q) => (
                                  <MenuItem key={q?.id} value={q?.id}>
                                    {q?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Typography
                                sx={{ marginInlineStart: "12px" }}
                                color={"error"}
                                fontSize={"small"}
                              >
                                {errors?.branches_ids?.message}
                              </Typography>
                            </StyledFormControl>
                          )}
                        />
                      </Box>
                    </Stack>
                    <Stack
                      mb={3}
                      direction={"row"}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ width: "min(310px, 100%)" }}>
                        <Typography mb={1} color={"GrayText"}>
                          {t("phone_number")}
                        </Typography>
                        <Controller
                          name="phone"
                          control={control}
                          defaultValue={""}
                          rules={{ maxLength: 14, minLength: 14 }}
                          render={({ field }) => (
                            <StyledTextField
                              {...field}
                              onChange={(e) => {
                                if (!isNaN(e.target.value)) {
                                  field.onChange(e.target.value);
                                }
                              }}
                              error={!!errors?.phone}
                              helperText={errors?.phone?.message}
                              placeholder={"00962XXXXXXXXX"}
                              size="small"
                            />
                          )}
                        />
                        {(errors?.phone?.type == "maxLength" ||
                          errors?.phone?.type == "minLength") && (
                          <Typography
                            sx={{ marginInlineStart: "12px" }}
                            color={"error"}
                            fontSize={"small"}
                          >
                            {t("phone_14")}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={{ width: "min(310px, 100%)" }}>
                        <Typography mb={1} color={"GrayText"}>
                          {t("address")}*
                        </Typography>
                        <Controller
                          name="address"
                          control={control}
                          defaultValue={""}
                          render={({ field }) => (
                            <StyledTextField
                              {...field}
                              error={!!errors?.address}
                              helperText={errors?.address?.message}
                              placeholder={t("address")}
                              size="small"
                            />
                          )}
                        />
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ width: "min(310px, 100%)" }}>
                        <Typography mb={1} color={"GrayText"}>
                          {t("password")}*
                        </Typography>
                        <Controller
                          name="password"
                          control={control}
                          defaultValue={""}
                          rules={{
                            required: t("password_required"),
                          }}
                          render={({ field }) => (
                            <StyledTextField
                              {...field}
                              error={!!errors?.password}
                              helperText={errors?.password?.message}
                              placeholder={t("password")}
                              size="small"
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        setShowPassword((prev) => !prev);
                                      }}
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Stack>
                    <Stack direction={"row"} sx={{ alignItems: "center" }}>
                      <Controller
                        name="privacy"
                        control={control}
                        defaultValue={""}
                        rules={{
                          required: t("privacy_required"),
                        }}
                        render={({ field }) => <Checkbox {...field} />}
                      />
                      <Typography>
                        {t("accept_the")}{" "}
                        <a
                          href="/privacy-usage-policy"
                          target="_blank"
                          style={{
                            color: "#0091F7",
                            textDecoration: "underline",
                          }}
                        >
                          {t("privacy_usage_policy")}
                        </a>
                      </Typography>
                    </Stack>
                    <Typography sx={{ fontSize: "14px" }} color={"error"}>
                      {errors?.privacy?.message}
                    </Typography>
                    <Typography
                      mb={2}
                      sx={{ fontSize: "14px" }}
                      color={"error"}
                      ref={errMessage}
                    ></Typography>
                    <Stack
                      direction={"row"}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <LoadingButton
                        loading={loadingSubmit}
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: "100vh",
                          height: "40px",
                          width: "100px",
                        }}
                      >
                        {t("register")}
                      </LoadingButton>
                      <Typography color={"GrayText"} fontSize={"0.8rem"}>
                        {t("have_account")}{" "}
                        <Link to={"/sign-in"} style={{ color: "#0091F7" }}>
                          {t("sign_in")}
                        </Link>
                      </Typography>
                    </Stack>
                  </Paper>
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default SignUp;
