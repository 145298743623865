import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const LoadingComponent = () => {
  return (
    <Box sx={{display: 'grid', placeItems: 'center', padding: '50px 0'}}>
      <CircularProgress color='primary'/>
    </Box>
  )
}

export default LoadingComponent